import { createAction } from 'redux-actions';
import { USER_ACTION_TYPES } from '../actionTypes/auth.actionTypes';

export const loginStart = createAction(USER_ACTION_TYPES.LOGIN.START, (email, password) => ({ email, password }));
export const loginInProgress = createAction(USER_ACTION_TYPES.LOGIN.IN_PROGRESS);
export const loginSuccess = createAction(USER_ACTION_TYPES.LOGIN.SUCCESS);
export const loginError = createAction(USER_ACTION_TYPES.LOGIN.ERROR, (error) => ({ error }));
export const loginReset = createAction(USER_ACTION_TYPES.LOGIN.RESET);

export const logoutStart = createAction(USER_ACTION_TYPES.LOGOUT.START);
export const logoutInProgress = createAction(USER_ACTION_TYPES.LOGOUT.IN_PROGRESS);
export const logoutSuccess = createAction(USER_ACTION_TYPES.LOGOUT.SUCCESS);
export const logoutError = createAction(USER_ACTION_TYPES.LOGOUT.ERROR, (error) => ({ error }));
export const logoutReset = createAction(USER_ACTION_TYPES.LOGOUT.RESET);

export const forgotPasswordStart = createAction(USER_ACTION_TYPES.FORGOT_PASSWORD.START, (email) => ({ email }));
export const forgotPasswordInProgress = createAction(USER_ACTION_TYPES.FORGOT_PASSWORD.IN_PROGRESS);
export const forgotPasswordSuccess = createAction(USER_ACTION_TYPES.FORGOT_PASSWORD.SUCCESS, (data) => ({ data }));
export const forgotPasswordError = createAction(USER_ACTION_TYPES.FORGOT_PASSWORD.ERROR, (error) => ({ error }));

export const resetPasswordStart = createAction(USER_ACTION_TYPES.RESET_PASSWORD.START, (token, password) => ({ token, password }));
export const resetPasswordInProgress = createAction(USER_ACTION_TYPES.RESET_PASSWORD.IN_PROGRESS);
export const resetPasswordSuccess = createAction(USER_ACTION_TYPES.RESET_PASSWORD.SUCCESS, (data) => ({ data }));
export const resetPasswordError = createAction(USER_ACTION_TYPES.FORGOT_PASSWORD.ERROR, (error) => ({ error })); (password) => ({ password });

export const changePasswordStart = createAction(
  USER_ACTION_TYPES.CHANGE_PASSWORD.START,
  (password) => ({ password })
);
export const changePasswordInProgress = createAction(
  USER_ACTION_TYPES.CHANGE_PASSWORD.IN_PROGRESS
);
export const changePasswordSuccess = createAction(
  USER_ACTION_TYPES.CHANGE_PASSWORD.SUCCESS,
  (data) => ({ data })
);
export const changePasswordError = createAction(
  USER_ACTION_TYPES.CHANGE_PASSWORD.ERROR,
  (error) => ({ error })
);

export const updateStart = createAction(USER_ACTION_TYPES.UPDATE.START, (first_name, last_name, email, mobile) => ({ first_name, last_name, email, mobile }));
export const updateInProgress = createAction(USER_ACTION_TYPES.UPDATE.IN_PROGRESS);
export const updateSuccess = createAction(USER_ACTION_TYPES.UPDATE.SUCCESS, (data) => ({ data }));
export const updateError = createAction(USER_ACTION_TYPES.UPDATE.ERROR, (error) => ({ error }));
export const updateReset = createAction(USER_ACTION_TYPES.UPDATE.RESET);

export const fetchAccountStart = createAction(USER_ACTION_TYPES.FETCH_ACCOUNT.START);
export const fetchAccountInProgress = createAction(USER_ACTION_TYPES.FETCH_ACCOUNT.IN_PROGRESS);
export const fetchAccountSuccess = createAction(USER_ACTION_TYPES.FETCH_ACCOUNT.SUCCESS, (data) => ({ data }));
export const fetchAccountError = createAction(USER_ACTION_TYPES.FETCH_ACCOUNT.ERROR, (error) => ({ error }));

