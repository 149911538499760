import { createSelector } from 'reselect';

const getResponsesState = (state) => state.brand;

/* ============================================ */
export const getBrandList = createSelector(
  getResponsesState,
  (brand) => brand.brandList.data
);

export const getFetchBrandListQueryParams = createSelector(
  getResponsesState,
  (brand) => brand.brandList.queryParams
);

export const getFetchBrandListStatus = createSelector(
  getResponsesState,
  (brand) => brand.brandList.request_status
);
export const getSaveBrandStatus = createSelector(
  getResponsesState,
  (brand) => brand.savedBrand.request_status
);
export const getBrand = createSelector(
  getResponsesState,
  (brand) => brand.brand.data
);
export const getAllBrands = createSelector(
  getResponsesState,
  (brand) => brand.brands.data
);
export const getEditBrandInitialValues = createSelector(
  getBrand,
  (brand) => {
    if (brand) {
      return {
        name: brand.name,
        distillery: brand.distillery,
        is_domestic: brand.is_domestic
      };
    }
    return brandInitialValues;
  }
);

export const brandInitialValues = {
  name: '',
  distillery: '',
  is_domestic: 0
};
