import { createAction } from 'redux-actions';
import { BRAND_ACTION_TYPES } from '../actionTypes/brand.actionTypes';

export const searchBrandsStart = createAction(BRAND_ACTION_TYPES.SEARCH_BRANDS.START, (queryParams) => ({ queryParams }));
export const searchBrandsInProgress = createAction(BRAND_ACTION_TYPES.SEARCH_BRANDS.IN_PROGRESS);
export const searchBrandsSuccess = createAction(BRAND_ACTION_TYPES.SEARCH_BRANDS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchBrandsError = createAction(BRAND_ACTION_TYPES.SEARCH_BRANDS.ERROR, (error) => ({ error }));
/* ============================================ */
export const createBrandStart = createAction(BRAND_ACTION_TYPES.CREATE_BRAND.START,
  (name, distillery, is_domestic) => ({ name, distillery, is_domestic }));
export const createBrandInProgress = createAction(BRAND_ACTION_TYPES.CREATE_BRAND.IN_PROGRESS);
export const createBrandSuccess = createAction(BRAND_ACTION_TYPES.CREATE_BRAND.SUCCESS, (data) => ({ data }));
export const createBrandError = createAction(BRAND_ACTION_TYPES.CREATE_BRAND.ERROR, (error) => ({ error }));
export const createBrandReset = createAction(BRAND_ACTION_TYPES.CREATE_BRAND.RESET);
/* ============================================ */
export const updateBrandStart = createAction(BRAND_ACTION_TYPES.UPDATE_BRAND.START,
  (brandId, name, distillery, is_domestic) => ({ brandId, name, distillery, is_domestic }));
export const updateBrandInProgress = createAction(BRAND_ACTION_TYPES.UPDATE_BRAND.IN_PROGRESS);
export const updateBrandSuccess = createAction(BRAND_ACTION_TYPES.UPDATE_BRAND.SUCCESS, (data) => ({ data }));
export const updateBrandError = createAction(BRAND_ACTION_TYPES.UPDATE_BRAND.ERROR, (error) => ({ error }));
export const updateBrandReset = createAction(BRAND_ACTION_TYPES.UPDATE_BRAND.RESET);

export const fetchBrandStart = createAction(BRAND_ACTION_TYPES.FETCH_BRAND.START, (id) => ({ id }));
export const fetchBrandInProgress = createAction(BRAND_ACTION_TYPES.FETCH_BRAND.IN_PROGRESS);
export const fetchBrandSuccess = createAction(BRAND_ACTION_TYPES.FETCH_BRAND.SUCCESS, (data) => ({ data }));
export const fetchBrandError = createAction(BRAND_ACTION_TYPES.FETCH_BRAND.ERROR, (error) => ({ error }));
export const fetchBrandReset = createAction(BRAND_ACTION_TYPES.FETCH_BRAND.RESET);
/* ============================================ */
export const fetchAllBrandsStart = createAction(BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.START);
export const fetchAllBrandsInProgress = createAction(BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.IN_PROGRESS);
export const fetchAllBrandsSuccess = createAction(BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.SUCCESS, (data) => ({ data }));
export const fetchAllBrandsError = createAction(BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteBrandStart = createAction(BRAND_ACTION_TYPES.DELETE_BRAND.START, (brandId, isOnlyItemOnPage) => ({brandId,isOnlyItemOnPage }));
export const deleteBrandInProgress = createAction(BRAND_ACTION_TYPES.DELETE_BRAND.IN_PROGRESS);
export const deleteBrandSuccess = createAction(BRAND_ACTION_TYPES.DELETE_BRAND.SUCCESS, (data) => ({ data }));
export const deleteBrandError = createAction(BRAND_ACTION_TYPES.DELETE_BRAND.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteBrandsStart = createAction(BRAND_ACTION_TYPES.DELETE_BRANDS.START, (brand_uuids) => ({brand_uuids}));
export const deleteBrandsInProgress = createAction(BRAND_ACTION_TYPES.DELETE_BRANDS.IN_PROGRESS);
export const deleteBrandsSuccess = createAction(BRAND_ACTION_TYPES.DELETE_BRANDS.SUCCESS, (data) => ({ data }));
export const deleteBrandsError = createAction(BRAND_ACTION_TYPES.DELETE_BRANDS.ERROR, (error) => ({ error }));