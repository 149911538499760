import { createSelector } from 'reselect';

const getResponsesState = (state) => state.warehouse;

/* ============================================ */
export const getWarehouseList = createSelector(
  getResponsesState,
  (warehouse) => warehouse.warehouseList.data
);

export const getFetchWarehouseListQueryParams = createSelector(
  getResponsesState,
  (warehouse) => warehouse.warehouseList.queryParams
);

export const getFetchWarehouseListStatus = createSelector(
  getResponsesState,
  (warehouse) => warehouse.warehouseList.request_status
);
export const getSaveWarehouseStatus = createSelector(
  getResponsesState,
  (warehouse) => warehouse.savedWarehouse.request_status
);
export const getWarehouse = createSelector(
  getResponsesState,
  (warehouse) => warehouse.warehouse.data
);
export const getAllWarehouses = createSelector(
  getResponsesState,
  (warehouse) => warehouse.warehouses.data
);
export const getEditWarehouseInitialValues = createSelector(
  getWarehouse,
  (warehouse) => {
    if (warehouse) {
      const modifiedContacts = prepareContacts(warehouse);
      const initvalues = {
        name: warehouse.name,
        street_line_1: warehouse.street_line_1 || '',
        street_line_2: warehouse.street_line_2 || '',
        city:warehouse.city || '',
        state_id:warehouse.state.uuid,
        zip:warehouse.zip || '',
        contacts: modifiedContacts
      };

      return initvalues;
    }
    return null;
  }
);

const prepareContacts = (warehouse) => {
  if(warehouse.contacts && warehouse.contacts.length > 0)
  {
    return warehouse.contacts.map((element) => {
      return {'phone':element.phone || '', 'email': element.email || '',
        'name': element.name || '', "is_primary": element.is_primary == 1?true:false}}
    );
  }
  return warehouseInitialValues.contacts;
}

export const warehouseInitialValues = {
  name: '',
  street_line_1: '',
  street_line_2: '',
  city:'',
  state_id:'',
  zip:'',
  contacts:[{
    name:'',
    email:'',
    phone:'',
    is_primary:false
  }],
};