import { createSelector } from 'reselect';

const getResponsesState = (state) => state.common;

/* ============================================ */
export const getSelectedValues = createSelector(
  getResponsesState,
  (common) => common.selectedValues
);

export const getCurrentOwner = createSelector(
  getResponsesState,
  (common) => common.current_owner_id
);


export const getCurrentStore = createSelector(
  getResponsesState,
  (common) => common.current_store_id
);

