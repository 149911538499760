import client from "./client";

export const searchStoreowners = (queryParams) => {
  return client.post(`/storeOwner/search`, queryParams);
}

export const createStoreowner = (name, password, email, phone, address, no_of_stores) => {
  return client.post(`/storeOwner`, { name, password, email, phone, address, no_of_stores });
}
export const updateStoreowner = (storeownerId, name, password, email, phone, address, no_of_stores) => {
  return client.put(`/storeOwner/${storeownerId}`, { name, password, email, phone, address, no_of_stores });

}
export const getStoreowner = (storeownerId) => {
  return client.get(`/storeOwner/${storeownerId}`);
}
export const deleteStoreowner = (storeownerId) =>{
   return client.delete(`/storeOwner/${storeownerId}/delete`);
}
export const deleteStoreowners = (user_ids) =>{
   return client.post(`/storeOwner/delete`,{user_ids});
}
