import { USER_ACTION_TYPES } from "../actionTypes/auth.actionTypes";
import { ACTION_STATUS, entityInitialState } from "../../utils/constants";

const initialState = {
  userLogin: {
    email: null,
    password: null,
    remember: false,
  },
  loginResponse: entityInitialState,
  logoutResponse: entityInitialState,
  forgotPasswordResponse: entityInitialState,
  resetPasswordResponse: entityInitialState,
  changePasswordResponse: entityInitialState,
  updateResponse: entityInitialState,
  fetchAccountResponse: entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_ACTION_TYPES.LOGIN.IN_PROGRESS:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.LOGIN.SUCCESS:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          request_status: ACTION_STATUS.SUCCESS,
        },
      };
    case USER_ACTION_TYPES.LOGIN.ERROR:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.LOGIN.RESET:
      return {
        ...state,
        userLogin: {
          ...initialState.userLogin,
        },
      };
    case USER_ACTION_TYPES.LOGOUT.SUCCESS:
      return {
        ...state,
        logoutResponse: {
          ...initialState.logoutResponse,
          request_status: ACTION_STATUS.SUCCESS,
        },
      };
    case USER_ACTION_TYPES.LOGOUT.RESET:
      return {
        ...state,
        logoutResponse: {
          ...initialState.logoutResponse,
        },
      };
    case USER_ACTION_TYPES.FORGOT_PASSWORD.IN_PROGRESS:
      return {
        ...state,
        forgotPasswordResponse: {
          ...state.forgotPasswordResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        forgotPasswordResponse: {
          ...state.forgotPasswordResponse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data,
        },
      };
    case USER_ACTION_TYPES.FORGOT_PASSWORD.ERROR:
      return {
        ...state,
        forgotPasswordResponse: {
          ...state.forgotPasswordResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.FORGOT_PASSWORD.RESET:
      return {
        ...state,
        userLogin: {
          ...initialState.userLogin,
        },
      };

    case USER_ACTION_TYPES.RESET_PASSWORD.IN_PROGRESS:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data,
        },
      };
    case USER_ACTION_TYPES.RESET_PASSWORD.ERROR:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.RESET_PASSWORD.RESET:
      return {
        ...state,
        userLogin: {
          ...initialState.userLogin,
        },
      };

    case USER_ACTION_TYPES.RESET_PASSWORD.IN_PROGRESS:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data,
        },
      };
    case USER_ACTION_TYPES.RESET_PASSWORD.ERROR:
      return {
        ...state,
        resetPasswordResponse: {
          ...state.resetPasswordResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };

    case USER_ACTION_TYPES.RESET_PASSWORD.RESET:
      return {
        ...state,
        userLogin: {
          ...initialState.userLogin,
        },
      };

    case USER_ACTION_TYPES.CHANGE_PASSWORD.IN_PROGRESS:
      return {
        ...state,
        changePasswordResponse: {
          ...state.changePasswordResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          data: {
            ...state.loginResponse.data,
            force_change_password: 0,
          },
        },
        changePasswordResponse: {
          ...state.changePasswordResponse,
          request_status: ACTION_STATUS.SUCCESS,
        },
      };
    case USER_ACTION_TYPES.CHANGE_PASSWORD.ERROR:
      return {
        ...state,
        changePasswordResponse: {
          ...state.changePasswordResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.CHANGE_PASSWORD.RESET:
      return {
        ...state,
        userLogin: {
          ...initialState.userLogin,
        },
      };
    case USER_ACTION_TYPES.UPDATE.IN_PROGRESS:
      return {
        ...state,
        updateResponse: {
          ...state.updateResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.UPDATE.SUCCESS:
      return {
        ...state,
        updateResponse: {
          ...state.updateResponse,
          request_status: ACTION_STATUS.SUCCESS,
          data: {
            ...state.loginResponse.data,
            first_name: action.payload.data.first_name,
            last_name: action.payload.data.last_name,
            email: action.payload.data.email,
            mobile: action.payload.data.mobile
          }
        },
      };
    case USER_ACTION_TYPES.UPDATE.ERROR:
      return {
        ...state,
        updateResponse: {
          ...state.updateResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.UPDATE.RESET:
      return {
        ...state,
        updateResponse: {
          ...initialState.updateResponse,
        },
      };

    case USER_ACTION_TYPES.UPDATE_LOGIN_RESPONSE:
      return {
        ...state,
        loginResponse: {
          ...state.updateResponse.data
        }
      }

    case USER_ACTION_TYPES.FETCH_ACCOUNT.IN_PROGRESS:
      return {
        ...state,
        fetchAccountResponse: {
          ...state.fetchAccountResponse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        },
      };
    case USER_ACTION_TYPES.FETCH_ACCOUNT.SUCCESS:
      return {
        ...state,
        fetchAccountResponse: {
          ...state.fetchAccountResponse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data,
        },
      };
    case USER_ACTION_TYPES.FETCH_ACCOUNT.ERROR:
      return {
        ...state,
        fetchAccountResponse: {
          ...state.fetchAccountResponse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload,
        },
      };
    case USER_ACTION_TYPES.FETCH_ACCOUNT.RESET:
      return {
        ...state,
        fetchAccountResponse: {
          ...initialState.fetchAccountResponse,
        },
      };

    default:
      return state;
  }
};

export default authReducer;
