import { BRAND_ACTION_TYPES } from '../actionTypes/brand.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  brandList: createPaginatedEntity({ name: "name", direction: "asc" }),
  brand: entityInitialState,
  brands: entityInitialState,
  savedBrand: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const brandReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case BRAND_ACTION_TYPES.SEARCH_BRANDS.IN_PROGRESS:
      return {
        ...state,
        brandList: {
          ...state.brandList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case BRAND_ACTION_TYPES.SEARCH_BRANDS.SUCCESS:
      return {
        ...state,
        brandList: {
          ...state.brandList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.brandList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case BRAND_ACTION_TYPES.SEARCH_BRANDS.ERROR:
      return {
        ...state,
        brandList: {
          ...state.brandList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case BRAND_ACTION_TYPES.SEARCH_BRANDS.RESET:
      return {
        ...state,
        brandList: {
          ...initialState.brandList
        }
      };
    /* ============================================ */
    case BRAND_ACTION_TYPES.CREATE_BRAND.IN_PROGRESS:
    case BRAND_ACTION_TYPES.UPDATE_BRAND.IN_PROGRESS:
    case BRAND_ACTION_TYPES.DELETE_BRAND.IN_PROGRESS:
    case BRAND_ACTION_TYPES.DELETE_BRANDS.IN_PROGRESS:
      return {
        ...state,
        savedBrand: {
          ...state.savedBrand,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case BRAND_ACTION_TYPES.CREATE_BRAND.SUCCESS:
    case BRAND_ACTION_TYPES.UPDATE_BRAND.SUCCESS:
    case BRAND_ACTION_TYPES.DELETE_BRAND.SUCCESS:
    case BRAND_ACTION_TYPES.DELETE_BRANDS.SUCCESS:
      return {
        ...state,
        savedBrand: {
          ...state.savedBrand,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case BRAND_ACTION_TYPES.CREATE_BRAND.ERROR:
    case BRAND_ACTION_TYPES.UPDATE_BRAND.ERROR:
    case BRAND_ACTION_TYPES.DELETE_BRAND.ERROR:
    case BRAND_ACTION_TYPES.DELETE_BRANDS.ERROR:
      return {
        ...state,
        savedBrand: {
          ...state.savedBrand,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case BRAND_ACTION_TYPES.CREATE_BRAND.RESET:
    case BRAND_ACTION_TYPES.UPDATE_BRAND.RESET:
    case BRAND_ACTION_TYPES.DELETE_BRAND.RESET:
    case BRAND_ACTION_TYPES.DELETE_BRANDS.RESET:
      return {
        ...state,
        savedBrand: {
          ...initialState.savedBrand
        }
      };
    /* ============================================ */
    case BRAND_ACTION_TYPES.FETCH_BRAND.IN_PROGRESS:
      return {
        ...state,
        brand: {
          ...state.brand,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case BRAND_ACTION_TYPES.FETCH_BRAND.SUCCESS:
      return {
        ...state,
        brand: {
          ...state.brand,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case BRAND_ACTION_TYPES.FETCH_BRAND.ERROR:
      return {
        ...state,
        brand: {
          ...state.brand,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case BRAND_ACTION_TYPES.FETCH_BRAND.RESET:
      return {
        ...state,
        brand: {
          ...initialState.brand
        }
      }
    // /* ============================================ */
    case BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.IN_PROGRESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.ERROR:
      return {
        ...state,
        brands: {
          ...state.brands,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.RESET:
      return {
        ...state,
        brands: {
          ...initialState.brands
        }
      };
    /* ============================================ */
    default:
      return state;
  }
}
export default brandReducer;