import { createAction } from 'redux-actions';
import { STORE_ACTION_TYPES } from '../actionTypes/store.actionTypes';

export const searchStoresStart = createAction(STORE_ACTION_TYPES.SEARCH_STORES.START, (storeOwnerId, queryParams) => ({ storeOwnerId, queryParams }));
export const searchStoresInProgress = createAction(STORE_ACTION_TYPES.SEARCH_STORES.IN_PROGRESS);
export const searchStoresSuccess = createAction(STORE_ACTION_TYPES.SEARCH_STORES.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchStoresError = createAction(STORE_ACTION_TYPES.SEARCH_STORES.ERROR, (error) => ({ error }));
/* ============================================ */
export const createStoreStart = createAction(STORE_ACTION_TYPES.CREATE_STORE.START,
  (name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments) => ({ name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments }));
export const createStoreInProgress = createAction(STORE_ACTION_TYPES.CREATE_STORE.IN_PROGRESS);
export const createStoreSuccess = createAction(STORE_ACTION_TYPES.CREATE_STORE.SUCCESS, (data) => ({ data }));
export const createStoreError = createAction(STORE_ACTION_TYPES.CREATE_STORE.ERROR, (error) => ({ error }));
export const createStoreReset = createAction(STORE_ACTION_TYPES.CREATE_STORE.RESET);
/* ============================================ */
export const updateStoreStart = createAction(STORE_ACTION_TYPES.UPDATE_STORE.START,
  (storeId, name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments) => ({ storeId, name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments }));
export const updateStoreInProgress = createAction(STORE_ACTION_TYPES.UPDATE_STORE.IN_PROGRESS);
export const updateStoreSuccess = createAction(STORE_ACTION_TYPES.UPDATE_STORE.SUCCESS, (data) => ({ data }));
export const updateStoreError = createAction(STORE_ACTION_TYPES.UPDATE_STORE.ERROR, (error) => ({ error }));
export const updateStoreReset = createAction(STORE_ACTION_TYPES.UPDATE_STORE.RESET);
/* ============================================ */
export const deleteStoreStart = createAction(STORE_ACTION_TYPES.DELETE_STORE.START, (storeId, store_owner_id,isOnlyItemOnPage) => ({ storeId, store_owner_id,isOnlyItemOnPage }));
export const deleteStoreInProgress = createAction(STORE_ACTION_TYPES.DELETE_STORE.IN_PROGRESS);
export const deleteStoreSuccess = createAction(STORE_ACTION_TYPES.DELETE_STORE.SUCCESS, (data) => ({ data }));
export const deleteStoreError = createAction(STORE_ACTION_TYPES.DELETE_STORE.ERROR, (error) => ({ error }));
/* ============================================ */
export const fetchStoreStart = createAction(STORE_ACTION_TYPES.FETCH_STORE.START, (storeId) => ({ storeId }));
export const fetchStoreInProgress = createAction(STORE_ACTION_TYPES.FETCH_STORE.IN_PROGRESS);
export const fetchStoreSuccess = createAction(STORE_ACTION_TYPES.FETCH_STORE.SUCCESS, (data) => ({ data }));
export const fetchStoreError = createAction(STORE_ACTION_TYPES.FETCH_STORE.ERROR, (error) => ({ error }));
export const fetchStoreReset = createAction(STORE_ACTION_TYPES.FETCH_STORE.RESET);
/* ============================================ */
export const fetchAllStoresStart = createAction(STORE_ACTION_TYPES.FETCH_ALL_STORES.START, (storeOwnerId) => ({ storeOwnerId}));
export const fetchAllStoresInProgress = createAction(STORE_ACTION_TYPES.FETCH_ALL_STORES.IN_PROGRESS);
export const fetchAllStoresSuccess = createAction(STORE_ACTION_TYPES.FETCH_ALL_STORES.SUCCESS, (data) => ({ data }));
export const fetchAllStoresError = createAction(STORE_ACTION_TYPES.FETCH_ALL_STORES.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteStoresStart = createAction(STORE_ACTION_TYPES.DELETE_STORES.START, (store_uuids, store_owner_id) => ({ store_uuids, store_owner_id}));
export const deleteStoresInProgress = createAction(STORE_ACTION_TYPES.DELETE_STORES.IN_PROGRESS);
export const deleteStoresSuccess = createAction(STORE_ACTION_TYPES.DELETE_STORES.SUCCESS, (data) => ({ data }));
export const deleteStoresError = createAction(STORE_ACTION_TYPES.DELETE_STORES.ERROR, (error) => ({ error }));
