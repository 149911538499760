import { createSelector } from 'reselect';

const getResponsesState = (state) => state.statetoproduct;

/* ============================================ */
export const getStateToProductList = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.stateToProductList.data
);

export const getFetchStateToProductListQueryParams = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.stateToProductList.queryParams
);

export const getFetchStateToProductListStatus = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.stateToProductList.request_status
);
export const getSaveStateToProductStatus = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.savedStateToProduct.request_status
);
export const getSaveStateToProduct = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.savedStateToProduct.data
);
export const getStateToProduct = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.stateToProduct.data
);
export const getAllStateToProducts = createSelector(
  getResponsesState,
  (statetoproduct) => statetoproduct.stateToProducts.data
);
