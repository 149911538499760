import * as actions from "../store/actions/auth.actions";
import * as storeActions from "../store/actions/common.actions";
import { USER_ACTION_TYPES } from "../store/actionTypes/auth.actionTypes";
import * as api from "../api/auth.api";
import { call, put, takeLatest } from "redux-saga/effects";
import { removeCookie, setCookie } from "../utils/utils";

import { notifier } from "../utils/notifier";
import { redirect } from "react-router-dom";

export function* login({ payload: { email, password } }) {
  try {
    yield put(actions.loginInProgress());
    const data = yield call(api.login, email, password);
    setCookie("X-AuthToken", data.authToken);
    console.log("setting cookie", data.authToken);
    yield put(actions.loginSuccess(data));
  } catch (error) {
    yield call(notifier.alert, "error", error.text);
    yield put(actions.loginError(error.text));
  }
}

export function* watchLogin() {
  yield takeLatest(USER_ACTION_TYPES.LOGIN.START, login);
}

export function* logout() {
  try {
    yield put(actions.logoutInProgress());
    const data = yield call(api.logout);
    removeCookie("X-AuthToken");
    yield put(actions.logoutSuccess());
    redirect("/login");
  } catch (error) {
    console.log(error)
    yield put(actions.logoutError(error.text));
    yield call(notifier.notify, "error", error.text);
  }
}

export function* watchLogout() {
  yield takeLatest(USER_ACTION_TYPES.LOGOUT.START, logout);
}

export function* forgotPassword({ payload: { email } }) {
  try {
    yield put(actions.forgotPasswordInProgress());
    const data = yield call(api.forgotPassword, email);

    yield put(actions.forgotPasswordSuccess(data));
    yield call(notifier.notify, "info", data.message);
  } catch (error) {

    yield call(notifier.alert, "error", error.text);
    yield put(actions.forgotPasswordError(error.text));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(USER_ACTION_TYPES.FORGOT_PASSWORD.START, forgotPassword);
}

export function* resetPassword({ payload: { token, password } }) {
  try {
    yield put(actions.resetPasswordInProgress());
    const data = yield call(api.resetPassword, token, password);
    yield call(notifier.notify, "info", "Password updated successfully. Please login using the new password.");
    yield put(actions.resetPasswordSuccess(data));

  } catch (error) {

    yield call(notifier.alert, "error", error.text);
    yield put(actions.resetPasswordError(error.text));
  }
}

export function* watchResetPassword() {
  yield takeLatest(USER_ACTION_TYPES.RESET_PASSWORD.START, resetPassword);
}

export function* changePassword({ payload: { password } }) {
  try {
    yield put(actions.changePasswordInProgress());
    const data = yield call(api.changePassword, password);

    yield put(actions.changePasswordSuccess(data));
    yield call(notifier.notify, "success", "Password Successfully Changed.");

  } catch (error) {

    yield put(actions.changePasswordError(error.text));
    yield call(notifier.alert, "error", error.text);
  }
}

export function* watchChangePassword() {
  yield takeLatest(USER_ACTION_TYPES.CHANGE_PASSWORD.START, changePassword);
}

export function* updateProfile({ payload: { first_name, last_name, email, mobile } }) {
  try {
    yield put(actions.updateInProgress());
    const data = yield call(api.update, first_name, last_name, email, mobile);
    yield put(actions.updateSuccess(data));
    const previous_user_data = JSON.parse(localStorage.getItem("loginResponse"));
    const updated_user_data = {
      ...previous_user_data,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      mobile: data.mobile
    }
    localStorage.setItem("loginResponse", JSON.stringify(updated_user_data));
    yield call(notifier.notify, "success", "Profile Successfully Updated.");
  } catch (error) {

    yield put(actions.updateError(error.text));
    yield call(notifier.alert, "error", error.text);
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(USER_ACTION_TYPES.UPDATE.START, updateProfile);
}


export function* fetchAccount() {
  try {
    yield put(actions.fetchAccountInProgress());
    const data = yield call(api.fetchAccount);
    yield put(actions.fetchAccountSuccess(data));
    yield put(storeActions.setSelectedStore(data.store_owner_id, data.store_id));
    
  } catch (error) {

    yield put(actions.updateError(error.text));
    yield call(notifier.alert, "error", error.text);
  }
}

export function* watchFetchAccount() {
  yield takeLatest(USER_ACTION_TYPES.FETCH_ACCOUNT.START, fetchAccount);
}