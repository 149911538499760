/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import WarehouseIcon from '@mui/icons-material/Warehouse';

// constant
const icons = { WarehouseIcon };

// ==============================|| Warehouse List MENU ITEMS ||============================== //

const warehouselist = {
    id: 'warehouselist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Warehouse List',
            type: 'item',
            url: '/warehouse',
            icon: icons.WarehouseIcon,
            breadcrumbs: false
        }
    ]
};

export default warehouselist;
