import { STOCK_ENTRY_ACTION_TYPES } from '../actionTypes/stockentry.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  savedStockEntry: entityInitialState,
  stockEntryList: createPaginatedEntity({ name: "name", direction: "asc" }),
  productsForStockEntry: entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const stockentryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    case STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.IN_PROGRESS:
      return {
        ...state,
        stockEntryList: {
          ...state.stockEntryList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.SUCCESS:
      return {
        ...state,
        stockEntryList: {
          ...state.stockEntryList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.stockEntryList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.ERROR:
      return {
        ...state,
        stockEntryList: {
          ...state.stockEntryList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.RESET:
      return {
        ...state,
        stockEntryList: {
          ...initialState.stockEntryList
        }
      };
    /* ============================================ */

    case STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.IN_PROGRESS:
      return {
        ...state,
        productsForStockEntry: {
          ...state.productsForStockEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.SUCCESS:
      return {
        ...state,
        productsForStockEntry: {
          ...state.productsForStockEntry,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.ERROR:
      return {
        ...state,
        productsForStockEntry: {
          ...state.productsForStockEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.RESET:
      return {
        ...state,
        productsForStockEntry: {
          ...initialState.productsForStockEntry
        }
      };

    /* ============================================ */
    case STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.IN_PROGRESS:
    // case STOCK_ENTRY_ACTION_TYPES.UPDATE_STOCK_ENTRY.IN_PROGRESS:
    // case STOCK_ENTRY_ACTION_TYPES.DELETE_STOCK_ENTRY.IN_PROGRESS:
      return {
        ...state,
        savedStockEntry: {
          ...state.savedStockEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.SUCCESS:
    // case STOCK_ENTRY_ACTION_TYPES.UPDATE_STOCK_ENTRY.SUCCESS:
    // case STOCK_ENTRY_ACTION_TYPES.DELETE_STOCK_ENTRY.SUCCESS:
      return {
        ...state,
        savedStockEntry: {
          ...state.savedStockEntry,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.ERROR:
    // case STOCK_ENTRY_ACTION_TYPES.UPDATE_STOCK_ENTRY.ERROR:
    // case STOCK_ENTRY_ACTION_TYPES.DELETE_STOCK_ENTRY.ERROR:
      return {
        ...state,
        savedStockEntry: {
          ...state.savedStockEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.RESET:
    // case STOCK_ENTRY_ACTION_TYPES.UPDATE_STOCK_ENTRY.RESET:
    // case STOCK_ENTRY_ACTION_TYPES.DELETE_STOCK_ENTRY.RESET:
      return {
        ...state,
        savedStockEntry: {
          ...initialState.savedStockEntry
        }
      };
    /* ============================================ */
    default:
      return state;
  }
}
export default stockentryReducer;