import { COMMON_ACTION_TYPES } from '../actionTypes/common.actionTypes';

const initialState = {
  current_owner_id: null,
  current_store_id: null,
  selectedValues: {
    owner_id: null,
    store_id: null
  }

};

/* eslint-disable default-case, no-param-reassign */
const commonReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case COMMON_ACTION_TYPES.SET_SELECTED_STOREOWNER:
      return {
        ...state,
        current_owner_id: action.payload.owner_id,
        current_store_id: null,
        selectedValues: {
          owner_id: action.payload.owner_id,
          store_id: null
        }
      };
    case COMMON_ACTION_TYPES.SET_SELECTED_STORE:
      return {
        ...state,
        ...(action.payload.owner_id != state.current_owner_id) && {current_owner_id: action.payload.owner_id},
        current_store_id: action.payload.store_id,
        selectedValues: {
          owner_id:action.payload.owner_id,
          store_id: action.payload.store_id,
        }
      };
      /* ============================================ */
    default:
      return state;
  }
}
export default commonReducer;