import { createAction } from 'redux-actions';
import { COMMON_ACTION_TYPES } from '../actionTypes/common.actionTypes';

/* ============================================ */
export const setSelectedStoreowner = createAction(COMMON_ACTION_TYPES.SET_SELECTED_STOREOWNER,
  (owner_id) => ({ owner_id }));

export const setSelectedStore = createAction(COMMON_ACTION_TYPES.SET_SELECTED_STORE,
  (owner_id, store_id) => ({ owner_id, store_id }));

export const setSelectedList = createAction(COMMON_ACTION_TYPES.SET_SELECTED_WAREHOUSE,
  (owner_id, store_id,entry_date, warehouse_id, brand_id) => ({ owner_id, store_id,entry_date, warehouse_id, brand_id })); 