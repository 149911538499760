/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import PlaceIcon from '@mui/icons-material/Place';

// constant
const icons = { PlaceIcon };

// ==============================|| State List MENU ITEMS ||============================== //

const statelist = {
    id: 'statelist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'State List',
            type: 'item',
            url: '/states',
            icon: icons.PlaceIcon,
            breadcrumbs: false
        }
    ]
};

export default statelist;
