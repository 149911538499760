import { PRODUCT_TO_STATE_ACTION_TYPES } from '../actionTypes/productToState.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  productToStateList: entityInitialState,
  productToState: entityInitialState,
  productToStates: entityInitialState,
  savedProductToState: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const productToStateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.IN_PROGRESS:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.SUCCESS:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.ERROR:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.RESET:
      return {
        ...state,
        productToStateList: {
          ...initialState.productToStateList
        }
      };
    /* ============================================ */
    case PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.IN_PROGRESS:
    case PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.IN_PROGRESS:
    case PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.IN_PROGRESS:
      return {
        ...state,
        savedProductToState: {
          ...state.savedProductToState,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.SUCCESS:
    case PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.SUCCESS:
    case PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.SUCCESS:
      return {
        ...state,
        savedProductToState: {
          ...state.savedProductToState,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.ERROR:
    case PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.ERROR:
    case PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.ERROR:
      return {
        ...state,
        savedProductToState: {
          ...state.savedProductToState,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.RESET:
    case PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.RESET:
    case PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.RESET:
      return {
        ...state,
        savedProductToState: {
          ...initialState.savedProductToState
        }
      };
    /* ============================================ */
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.IN_PROGRESS:
      return {
        ...state,
        productToState: {
          ...state.productToState,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.SUCCESS:
      return {
        ...state,
        productToState: {
          ...state.productToState,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.ERROR:
      return {
        ...state,
        productToState: {
          ...state.productToState,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.RESET:
      return {
        ...state,
        productToState: {
          ...initialState.productToState
        }
      }
    // /* ============================================ */
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.IN_PROGRESS:
      return {
        ...state,
        productToStates: {
          ...state.productToStates,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.SUCCESS:
      return {
        ...state,
        productToStates: {
          ...state.productToStates,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.ERROR:
      return {
        ...state,
        productToStates: {
          ...state.productToStates,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.RESET:
      return {
        ...state,
        productToStates: {
          ...initialState.productToStates
        }
      };
    /* ============================================ */
    default:
      return state;
  }
}
export default productToStateReducer;