/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import Inventory2Icon from '@mui/icons-material/Inventory2';

// constant
const icons = { Inventory2Icon };

// ==============================|| Stock List MENU ITEMS ||============================== //

const stocklist = {
    id: 'stocklist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Stock List',
            type: 'item',
            url: '/stocklist',
            icon: icons.Inventory2Icon,
            breadcrumbs: false
        }
    ]
};

export default stocklist;
