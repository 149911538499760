import client from "./client";

export const searchStoreusers = (storeOwnerId,queryParams) => {
  return client.post(`/storeStaff/${storeOwnerId}/search`, queryParams);
}

export const createStoreuser = (employer_id,name, password,email,phone,address,profile_type,stores) => {
  return client.post(`/storeStaff/`, {employer_id,name, password,email,phone,address,profile_type,stores });
}
export const updateStoreuser = (userId, name, password,email,phone,address,profile_type,stores,employer_id) => {
  return client.put(`/storeStaff/${userId}`, { name, password,email,phone,address,profile_type,stores,employer_id});

}
export const getStoreuser = (storeStaffId) => {
  return client.get(`/storeStaff/${storeStaffId}`);

}
export const deleteStoreUser = (userId,storeownerId) =>{
   return client.delete(`/storeStaff/${userId}/delete`,storeownerId);
}
