import client from "./client";

export const searchProductToStates = (masterProductId,stateId) => {
  return client.post(`product/${masterProductId}/states`,stateId);
}

export const createProductToState = (stateId,masterProductId,volumes) => {
  return client.post(`/state/${stateId}/products/update/${masterProductId}`,volumes);
}
export const updateProductToState = (brandId, name, distillery, is_domestic) => {
  return client.put(`/brand/${brandId}`, { name, distillery, is_domestic });

}
export const getProductToState = (stateToProductId) => {
  return client.get(`/productToState/${stateToProductId}`);

}
export const deleteProductToState = (stateId,productId) =>{
   return client.delete(`/state/${stateId}/product/${productId}/delete`);
}