import { createAction } from 'redux-actions';
import { SALES_ENTRY_ACTION_TYPES } from '../actionTypes/salesentry.actionTypes';

export const searchSalesEntrysStart = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.START, (queryParams) => ({ queryParams }));
export const searchSalesEntrysInProgress = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.IN_PROGRESS);
export const searchSalesEntrysSuccess = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchSalesEntrysError = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.ERROR, (error) => ({ error }));
/* ============================================ */
export const searchProductForSalesEntryStart = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.START, 
  (store_id,entry_date, product_type, master_product_id, brand_id) => ({ store_id,entry_date, product_type, master_product_id, brand_id }));
export const searchProductForSalesEntryInProgress = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.IN_PROGRESS);
export const searchProductForSalesEntrySuccess = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.SUCCESS, (data) => ({ data }));
export const searchProductForSalesEntryError = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.ERROR, (error) => ({ error }));
/* ============================================ */
export const createSalesEntryStart = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.START,
  ( store_id, entry_date,sales_entries) => ({  store_id, entry_date,sales_entries }));
export const createSalesEntryInProgress = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.IN_PROGRESS);
export const createSalesEntrySuccess = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.SUCCESS, (data) => ({ data }));
export const createSalesEntryError = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.ERROR, (error) => ({ error }));
export const createSalesEntryReset = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.RESET);
/* ============================================ */
export const searchProductForPaymentEntryStart = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.START, 
  (store_id,entry_date) => ({ store_id,entry_date }));
export const searchProductForPaymentEntryInProgress = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.IN_PROGRESS);
export const searchProductForPaymentEntrySuccess = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.SUCCESS, (data) => ({ data }));
export const searchProductForPaymentEntryError = createAction(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.ERROR, (error) => ({ error }));
/* ============================================ */
export const createPaymentEntryStart = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.START,
  ( store_id, entry_date,payment_entries) => ({  store_id, entry_date,payment_entries }));
export const createPaymentEntryInProgress = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.IN_PROGRESS);
export const createPaymentEntrySuccess = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.SUCCESS, (data) => ({ data }));
export const createPaymentEntryError = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.ERROR, (error) => ({ error }));
export const createPaymentEntryReset = createAction(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.RESET);