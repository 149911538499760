import { combineReducers } from 'redux';

// reducer import
import appReducer from './app.reducer';
import { USER_ACTION_TYPES } from '../actionTypes/auth.actionTypes'
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = (state, action) => {
  if (action.type === USER_ACTION_TYPES.LOGOUT.SUCCESS) {
      return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
