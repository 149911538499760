import { createSelector } from 'reselect';

const getResponsesState = (state) => state.product;

/* ============================================ */
export const getProductList = createSelector(
  getResponsesState,
  (product) => product.productList.data
);

export const getFetchProductListQueryParams = createSelector(
  getResponsesState,
  (product) => product.productList.queryParams
);

export const getFetchProductListStatus = createSelector(
  getResponsesState,
  (product) => product.productList.request_status
);
export const getSaveProductStatus = createSelector(
  getResponsesState,
  (product) => product.savedProduct.request_status
);
export const getProduct = createSelector(
  getResponsesState,
  (product) => product.product.data
);
export const getProductInfo = createSelector(
  getResponsesState,
  (product) => product.productinfo.data
);
export const getAllProducts = createSelector(
  getResponsesState,
  (product) => product.products.data
);
export const getAllMasterProducts = createSelector(
  getResponsesState,
  (product) => product.masterProducts.data
);
export const getInitialQuantityList = createSelector(
  getResponsesState,
  (product) => product.initialQuantityList.data
);
export const getEditProductInitialValues = createSelector(
  getProduct,
  (product) => {
    if (product) {
      const volume_ids = [];
      if (product.volumes) {
        product.volumes.forEach(volume => {
          volume_ids.push(volume.volume_id);
        });
      }

      return {
        name: product.name,
        volume_ids: volume_ids,
        type: product.type,
        brand_id: product.brand.uuid
      };
    }

    return productInitialValues;
  }
);

export const productInitialValues = {
  name: '',
  volume_ids: [],
  type: '',
  brand_id: ''
};
