import { PRODUCT_ACTION_TYPES } from '../actionTypes/product.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  productList: createPaginatedEntity({ name: "name", direction: "asc" }),
  product: entityInitialState,
  savedProduct: entityInitialState,
  productinfo: entityInitialState,
  products: entityInitialState,
  masterProducts: entityInitialState,
  initialQuantityList: entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const productReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */

    case PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.IN_PROGRESS:
      return {
        ...state,
        productList: {
          ...state.productList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.SUCCESS:
      return {
        ...state,
        productList: {
          ...state.productList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.productList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.ERROR:
      return {
        ...state,
        productList: {
          ...state.productList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.RESET:
      return {
        ...state,
        productList: {
          ...initialState.productList
        }
      };
    /* ============================================ */
    case PRODUCT_ACTION_TYPES.CREATE_PRODUCT.IN_PROGRESS:
    case PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.IN_PROGRESS:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCT.IN_PROGRESS:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.IN_PROGRESS:
      return {
        ...state,
        savedProduct: {
          ...state.savedProduct,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.CREATE_PRODUCT.SUCCESS:
    case PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.SUCCESS:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCT.SUCCESS:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.SUCCESS:
      return {
        ...state,
        savedProduct: {
          ...state.savedProduct,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case PRODUCT_ACTION_TYPES.CREATE_PRODUCT.ERROR:
    case PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.ERROR:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCT.ERROR:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.ERROR:
      return {
        ...state,
        savedProduct: {
          ...state.savedProduct,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.CREATE_PRODUCT.RESET:
    case PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.RESET:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCT.RESET:
    case PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.RESET:
      return {
        ...state,
        savedProduct: {
          ...initialState.savedProduct
        }
      };
    /* ============================================ */
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT.IN_PROGRESS:
      return {
        ...state,
        product: {
          ...state.product,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT.SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT.ERROR:
      return {
        ...state,
        product: {
          ...state.product,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCT.RESET:
      return {
        ...state,
        product: {
          ...initialState.product
        }
      }
    /* ============================================ */
    /* ============================================ */
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.IN_PROGRESS:
      return {
        ...state,
        productinfo: {
          ...state.productinfo,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.SUCCESS:
      return {
        ...state,
        productinfo: {
          ...state.productinfo,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.ERROR:
      return {
        ...state,
        productinfo: {
          ...state.productinfo,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.RESET:
      return {
        ...state,
        productinfo: {
          ...initialState.productinfo
        }
      }
    // /* ============================================ */
    case PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.IN_PROGRESS:
      return {
        ...state,
        products: {
          ...state.products,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.ERROR:
      return {
        ...state,
        products: {
          ...state.products,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.RESET:
      return {
        ...state,
        products: {
          ...initialState.products
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.IN_PROGRESS:
      return {
        ...state,
        masterProducts: {
          ...state.masterProducts,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.SUCCESS:
      return {
        ...state,
        masterProducts: {
          ...state.masterProducts,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.ERROR:
      return {
        ...state,
        masterProducts: {
          ...state.masterProducts,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.RESET:
      return {
        ...state,
        masterProducts: {
          ...initialState.masterProducts
        }
      };
      case PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.IN_PROGRESS:
      return {
        ...state,
        initialQuantityList: {
          ...state.initialQuantityList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.SUCCESS:
      return {
        ...state,
        initialQuantityList: {
          ...state.initialQuantityList,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.ERROR:
      return {
        ...state,
        initialQuantityList: {
          ...state.initialQuantityList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.RESET:
      return {
        ...state,
        initialQuantityList: {
          ...initialState.initialQuantityList
        }
      };
    default:
      return state;
  }
}
export default productReducer;