import client from "./client";

export const searchStatess = (queryParams) => {
  
  return client.post(`/state/search`, queryParams);
}

export const createStates = (name) => {
  return client.post(`/state`, { name });
}
export const updateStates = (stateId, name) => {
  return client.put(`/state/${stateId}`, { name });

}
export const getStates = (stateId) => {
 
  return client.get(`/state/${stateId}`);

}
export const searchProductToState = (productId) => {
  return client.post(`/productToState/${productId}/search`);
}
export const deleteStates = (stateId) =>{
    return client.delete(`/state/${stateId}/delete`);
}