import * as actions from '../../src/store/actions/product.actions';
import { PRODUCT_ACTION_TYPES } from '../../src/store/actionTypes/product.actionTypes';
import * as api from '../api/product.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchProductListQueryParams
}
  from '../store/selectors/product.selectors';

/* ============================================ */
export function* searchProducts({ payload: { queryParams } }) {
  try {
    yield put(actions.searchProductsInProgress());
    const paramsFromState = yield select(getFetchProductListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchProducts,
      params

    );
    yield put(actions.searchProductsSuccess(data, params));
  } catch (error) {
    yield put(actions.searchProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProducts() {
  yield takeLatest(PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.START, searchProducts);
}
/* ============================================ */
export function* createProduct({ payload: { name, volume_ids, type, brand_id } }) {
  try {
    yield put(actions.createProductInProgress());

    const data = yield call(
      api.createProduct,
      name, volume_ids, type, brand_id
    );
    yield call(notifier.notify, 'success', "Product added to database");
    yield put(actions.createProductSuccess(data));
    yield put(actions.searchProductsStart());

  } catch (error) {
    yield put(actions.createProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateProduct() {
  yield takeLatest(PRODUCT_ACTION_TYPES.CREATE_PRODUCT.START, createProduct);
}
/* ============================================ */
export function* updateProduct({ payload: { masterProductId, name, volume_ids, type, brand_id } }) {
  try {
    yield put(actions.updateProductInProgress());

    const data = yield call(
      api.updateProduct,
      masterProductId,
      name,
      volume_ids, type, brand_id
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateProductSuccess(data));
    yield put(actions.searchProductsStart());

  } catch (error) {
    yield put(actions.updateProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateProduct() {
  yield takeLatest(PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.START, updateProduct);
}
/* ============================================ */
export function* deleteProduct({ payload: { productId, isOnlyItemOnPage} }) {
  try {
    yield put(actions.deleteProductInProgress());
    const data = yield call(
      api.deleteProduct,
      productId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteProductSuccess(data));
    const paramsFromState = yield select(getFetchProductListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchProductsStart({...paramsFromState, page: (paramsFromState.page-1)}));
    }else{
      yield put(actions.searchProductsStart( ));
    }

  } catch (error) {
    yield put(actions.deleteProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteProduct() {
  yield takeLatest(PRODUCT_ACTION_TYPES.DELETE_PRODUCT.START, deleteProduct);
}
/* ============================================ */
export function* fetchProduct({ payload: { id } }) {
  try {
    yield put(actions.fetchProductInProgress());
    const data = yield call(
      api.getProduct,
      id
    );
    yield put(actions.fetchProductSuccess(data));
  } catch (error) {
    yield put(actions.fetchProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchProduct() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCT.START, fetchProduct);
}
/* ============================================ */
export function* fetchProductInfo({ payload: { id } }) {
  try {
    yield put(actions.fetchProductInfoInProgress());
    const data = yield call(
      api.getProductInfo,
      id
    );
    yield put(actions.fetchProductInfoSuccess(data));
  } catch (error) {
    yield put(actions.fetchProductInfoError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchProductInfo() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.START, fetchProductInfo);
}

export function* fetchAllProducts() {
  try {
    yield put(actions.fetchAllProductsInProgress());
    const paramsFromState = yield select(getFetchProductListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchProducts,
      queryParams
    );
    yield put(actions.fetchAllProductsSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllProducts() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.START, fetchAllProducts);
}
export function* fetchAllMasterProducts({ payload: { brandId } }) {
  try {
    yield put(actions.fetchAllMasterProductsInProgress());
    
    
    const data = yield call(
      api.getBrandMasterProduct,
      brandId
    );
    yield put(actions.fetchAllMasterProductsSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllMasterProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllMasterProducts() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.START, fetchAllMasterProducts);
}
export function* fetchInitialQuantityList({ payload: { brandId,storeId } }) {
  try {
    yield put(actions.fetchInitialQuantityListInProgress());
    
    
    const data = yield call(
      api.getInitialQuantity,
      brandId,storeId
    );
    yield put(actions.fetchInitialQuantityListSuccess(data));
  } catch (error) {
    yield put(actions.fetchInitialQuantityListError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchInitialQuantityList() {
  yield takeLatest(PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.START, fetchInitialQuantityList);
}

/* ============================================ */
export function* deleteProducts({ payload: { product_uuids} }) {
  try {
    yield put(actions.deleteProductsInProgress());
    const data = yield call(
      api.deleteProducts,
      product_uuids
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteProductsSuccess(data));

      yield put(actions.searchProductsStart( ));

  } catch (error) {
    yield put(actions.deleteProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteProducts() {
  yield takeLatest(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.START, deleteProducts);
}