import * as actions from '../../src/store/actions/stocklist.actions';
import { STOCK_LIST_ACTION_TYPES } from '../../src/store/actionTypes/stocklist.actionTypes';
import * as api from '../api/stocklist.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchStockListQueryParams
}
  from '../store/selectors/stocklist.selectors';

/* ============================================ */
export function* searchStockList({ payload: {queryParams } }) {
  try {
    yield put(actions.searchStockListInProgress());
    const paramsFromState = yield select(getFetchStockListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStockList,
      params

    );
    yield put(actions.searchStockListSuccess(data, params));
  } catch (error) {
    yield put(actions.searchStockListError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStockList() {
  yield takeLatest(STOCK_LIST_ACTION_TYPES.SEARCH_STOCK_LIST.START, searchStockList);
}
/* ============================================ */