import * as actions from '../../src/store/actions/storeuser.actions';
import { STOREUSER_ACTION_TYPES } from '../../src/store/actionTypes/storeuser.actionTypes';
import * as api from '../api/storeuser.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchStoreuserListQueryParams
}
  from '../store/selectors/storeuser.selectors';

/* ============================================ */
export function* searchStoreusers({ payload: { store_owner_id, queryParams } }) {
  try {
    yield put(actions.searchStoreusersInProgress());
    const paramsFromState = yield select(getFetchStoreuserListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStoreusers,
      store_owner_id, params

    );
    yield put(actions.searchStoreusersSuccess(data, params));
  } catch (error) {
    yield put(actions.searchStoreusersError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStoreusers() {
  yield takeLatest(STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.START, searchStoreusers);
}
/* ============================================ */
export function* createStoreuser({ payload: { store_owner_id, name, password, email, phone, address, profile_type, stores } }) {
  try {
    yield put(actions.createStoreuserInProgress());

    const data = yield call(
      api.createStoreuser,
      store_owner_id, name, password, email, phone, address, profile_type, stores
    );
    yield call(notifier.notify, 'success', "Storeuser added to database");
    yield put(actions.createStoreuserSuccess(data));
    yield put(actions.searchStoreusersStart(store_owner_id));

  } catch (error) {
    yield put(actions.createStoreuserError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStoreuser() {
  yield takeLatest(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.START, createStoreuser);
}
/* ============================================ */
export function* updateStoreuser({ payload: { userId, name, password, email, phone, address, profile_type, stores, store_owner_id } }) {
  try {
    yield put(actions.updateStoreuserInProgress());

    const data = yield call(
      api.updateStoreuser,
      userId,
      name, password, email, phone, address, profile_type, stores, store_owner_id
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateStoreuserSuccess(data));
    yield put(actions.searchStoreusersStart(store_owner_id));

  } catch (error) {
    yield put(actions.updateStoreuserError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateStoreuser() {
  yield takeLatest(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.START, updateStoreuser);
}
/* ============================================ */
export function* deleteStoreUser({ payload: { userId, storeownerId ,isOnlyItemOnPage } }) {
  try {
    yield put(actions.deleteStoreUserInProgress());
    const data = yield call(
      api.deleteStoreUser,
      userId, storeownerId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStoreUserSuccess(data));
    const paramsFromState = yield select(getFetchStoreuserListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchStoreusersStart(storeownerId,{...paramsFromState, page: (paramsFromState.page-1)}));
    }else{
      yield put(actions.searchStoreusersStart( storeownerId ));
    }

  } catch (error) {
    yield put(actions.deleteStoreUserError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteStoreUser() {
  yield takeLatest(STOREUSER_ACTION_TYPES.DELETE_STOREUSER.START, deleteStoreUser);
}
/* ============================================ */
export function* fetchStoreuser({ payload: { storeStaffId } }) {
  try {
    yield put(actions.fetchStoreuserInProgress());
    const data = yield call(
      api.getStoreuser,
      storeStaffId
    );
    yield put(actions.fetchStoreuserSuccess(data));
  } catch (error) {
    yield put(actions.fetchStoreuserError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchStoreuser() {
  yield takeLatest(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.START, fetchStoreuser);
}