import { createActionTypes } from '../../utils/actionTypeCreator';

export const BRAND_ACTION_TYPES = {  
  CREATE_BRAND: createActionTypes("CREATE_BRAND"),
  UPDATE_BRAND:createActionTypes("UPDATE_BRAND"),
  SEARCH_BRANDS: createActionTypes('SEARCH_BRANDS'),
  FETCH_BRAND:createActionTypes('FETCH_BRAND'),
  FETCH_ALL_BRANDS: createActionTypes('FETCH_ALL_BRANDS'),
  DELETE_BRAND: createActionTypes('DELETE_BRAND'),
  DELETE_BRANDS: createActionTypes('DELETE_BRANDS')
};