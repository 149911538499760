import * as actions from '../../src/store/actions/salesentry.actions';
import { SALES_ENTRY_ACTION_TYPES } from '../../src/store/actionTypes/salesentry.actionTypes';
import * as api from '../api/salesentry.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchSalesEntryListQueryParams
}
  from '../store/selectors/salesentry.selectors';

/* ============================================ */
export function* searchSalesEntrys({ payload: { queryParams } }) {
  try {
    yield put(actions.searchSalesEntrysInProgress());
    const paramsFromState = yield select(getFetchSalesEntryListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchSalesEntrys,
      params

    );
    yield put(actions.searchSalesEntrysSuccess(data, params));
  } catch (error) {
    yield put(actions.searchSalesEntrysError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchSalesEntrys() {
  yield takeLatest(SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.START, searchSalesEntrys);
}
/* ============================================ */
export function* searchProductsForSalesEntry({ payload: { store_id, entry_date, product_type, master_product_id, brand_id } }) {
  try {
    yield put(actions.searchProductForSalesEntryInProgress());
    const data = yield call(
      api.searchProductsForSalesEntry,
      store_id, entry_date, product_type, master_product_id, brand_id
    );

    yield put(actions.searchProductForSalesEntrySuccess(data));
  } catch (error) {
    yield put(actions.searchProductForSalesEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProductsForSalesEntry() {
  yield takeLatest(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.START, searchProductsForSalesEntry);
}

/* ============================================ */
export function* createSalesEntry({ payload: {  store_id, entry_date, sales_entries } }) {
  try {
    yield put(actions.createSalesEntryInProgress());

    const data = yield call(
      api.createSalesEntry,
      store_id, entry_date, sales_entries
    );
    yield call(notifier.notify, 'success', "Sales Entry added to database");
    yield put(actions.createSalesEntrySuccess(data));

  } catch (error) {
    yield put(actions.createSalesEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateSalesEntry() {
  yield takeLatest(SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.START, createSalesEntry);
}
/* ============================================ */
export function* searchProductsForPaymentEntry({ payload: { store_id, entry_date} }) {
  try {
    yield put(actions.searchProductForPaymentEntryInProgress());
    const data = yield call(
      api.searchProductsForPaymentEntry,
      store_id, entry_date
    );

    yield put(actions.searchProductForPaymentEntrySuccess(data));
  } catch (error) {
    yield put(actions.searchProductForPaymentEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProductsForPaymentEntry() {
  yield takeLatest(SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.START, searchProductsForPaymentEntry);
}

/* ============================================ */
export function* createPaymentEntry({ payload: {  store_id, entry_date, payment_entries } }) {
  try {
    yield put(actions.createPaymentEntryInProgress());

    const data = yield call(
      api.createPaymentEntry,
      store_id, entry_date, payment_entries
    );
    yield call(notifier.notify, 'success', "Sales Payment Entry added to database");
    yield put(actions.createPaymentEntrySuccess(data));

  } catch (error) {
    yield put(actions.createPaymentEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreatePaymentEntry() {
  yield takeLatest(SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.START, createPaymentEntry);
}

