
import { all, fork } from 'redux-saga/effects';

import * as sagas from './index';



/*
    A few different options here for our rootSaga effect styles
    I've chosen for this implementation to go with nesting
    our forks inside an all effect
    https://redux-saga.js.org/docs/advanced/RootSaga.html
 */

const combinedSagas = {
  ...sagas,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map((saga) => fork(saga)));
}
