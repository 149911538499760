import { createAction } from 'redux-actions';
import { VOLUME_ACTION_TYPES } from '../actionTypes/volume.actionTypes';

export const fetchVolumeStart = createAction(VOLUME_ACTION_TYPES.FETCH_VOLUME.START, () => ({  }));
export const fetchVolumeInProgress = createAction(VOLUME_ACTION_TYPES.FETCH_VOLUME.IN_PROGRESS);
export const fetchVolumeSuccess = createAction(VOLUME_ACTION_TYPES.FETCH_VOLUME.SUCCESS, (data) => ({ data }));
export const fetchVolumeError = createAction(VOLUME_ACTION_TYPES.FETCH_VOLUME.ERROR, (error) => ({ error }));
export const fetchVolumeReset = createAction(VOLUME_ACTION_TYPES.FETCH_VOLUME.RESET);

export const fetchPaymentMethodStart = createAction(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.START, () => ({  }));
export const fetchPaymentMethodInProgress = createAction(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.IN_PROGRESS);
export const fetchPaymentMethodSuccess = createAction(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.SUCCESS, (data) => ({ data }));
export const fetchPaymentMethodError = createAction(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.ERROR, (error) => ({ error }));
export const fetchPaymentMethodReset = createAction(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.RESET);