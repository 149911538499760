import { createAction } from 'redux-actions';
import {  PRODUCT_ACTION_TYPES } from '../actionTypes/product.actionTypes';

export const searchProductsStart = createAction( PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.START, (queryParams) => ({ queryParams }));
export const searchProductsInProgress = createAction( PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.IN_PROGRESS);
export const searchProductsSuccess = createAction( PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchProductsError = createAction( PRODUCT_ACTION_TYPES.SEARCH_PRODUCTS.ERROR, (error) => ({ error }));
/* ============================================ */
export const createProductStart = createAction( PRODUCT_ACTION_TYPES.CREATE_PRODUCT.START,
  ( name,volume_ids, type,brand_id) => ({  name,volume_ids, type,brand_id }));
export const createProductInProgress = createAction( PRODUCT_ACTION_TYPES.CREATE_PRODUCT.IN_PROGRESS);
export const createProductSuccess = createAction( PRODUCT_ACTION_TYPES.CREATE_PRODUCT.SUCCESS, (data) => ({ data }));
export const createProductError = createAction( PRODUCT_ACTION_TYPES.CREATE_PRODUCT.ERROR, (error) => ({ error }));
export const createProductReset = createAction( PRODUCT_ACTION_TYPES.CREATE_PRODUCT.RESET);
/* ============================================ */
export const updateProductStart = createAction( PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.START,
  (masterProductId, name,volume_ids, type,brand_id) => ({ masterProductId,  name,volume_ids, type,brand_id }));
export const updateProductInProgress = createAction( PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.IN_PROGRESS);
export const updateProductSuccess = createAction( PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.SUCCESS, (data) => ({ data }));
export const updateProductError = createAction( PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.ERROR, (error) => ({ error }));
export const updateProductReset = createAction( PRODUCT_ACTION_TYPES.UPDATE_PRODUCT.RESET);
/* ============================================ */
export const deleteProductStart = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCT.START, (productId,isOnlyItemOnPage) => ({productId,isOnlyItemOnPage}));
export const deleteProductInProgress = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCT.IN_PROGRESS);
export const deleteProductSuccess = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCT.SUCCESS, (data) => ({ data }));
export const deleteProductError = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCT.ERROR, (error) => ({ error }));
/* ============================================ */
export const fetchProductStart = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCT.START, (id) => ({ id }));
export const fetchProductInProgress = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCT.IN_PROGRESS);
export const fetchProductSuccess = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCT.SUCCESS, (data) => ({ data }));
export const fetchProductError = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCT.ERROR, (error) => ({ error }));
export const fetchProductReset = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCT.RESET);
/* ============================================ */
export const fetchProductInfoStart = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.START, (id) => ({ id }));
export const fetchProductInfoInProgress = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.IN_PROGRESS);
export const fetchProductInfoSuccess = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.SUCCESS, (data) => ({ data }));
export const fetchProductInfoError = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.ERROR, (error) => ({ error }));
export const fetchProductInfoReset = createAction( PRODUCT_ACTION_TYPES.FETCH_PRODUCTINFO.RESET);
/* ============================================ */
export const fetchAllProductsStart = createAction( PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.START);
export const fetchAllProductsInProgress = createAction( PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.IN_PROGRESS);
export const fetchAllProductsSuccess = createAction( PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.SUCCESS, (data) => ({ data }));
export const fetchAllProductsError = createAction( PRODUCT_ACTION_TYPES.FETCH_ALL_PRODUCTS.ERROR, (error) => ({ error }));
/* ============================================ */
export const fetchAllMasterProductsStart = createAction(PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.START, (brandId) => ({brandId }));
export const fetchAllMasterProductsInProgress = createAction(PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.IN_PROGRESS);
export const fetchAllMasterProductsSuccess = createAction(PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.SUCCESS, (data) => ({ data }));
export const fetchAllMasterProductsError = createAction(PRODUCT_ACTION_TYPES.FETCH_ALL_MASTERPRODUCTS.ERROR, (error) => ({ error }));
/* ============================================ */
export const fetchInitialQuantityListStart = createAction(PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.START, (brandId,storeId) => ({brandId,storeId }));
export const fetchInitialQuantityListInProgress = createAction(PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.IN_PROGRESS);
export const fetchInitialQuantityListSuccess = createAction(PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.SUCCESS, (data) => ({ data }));
export const fetchInitialQuantityListError = createAction(PRODUCT_ACTION_TYPES.FETCH_INITIAL_QUANTITY.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteProductsStart = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.START, (product_uuids) => ({product_uuids}));
export const deleteProductsInProgress = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.IN_PROGRESS);
export const deleteProductsSuccess = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.SUCCESS, (data) => ({ data }));
export const deleteProductsError = createAction(PRODUCT_ACTION_TYPES.DELETE_PRODUCTS.ERROR, (error) => ({ error }));