import client from "./client";

export const searchSalesEntrys = (queryParams) => {
  return client.post(`/salesSummary/search`, queryParams);
}


export const searchProductsForSalesEntry = (store_id,entry_date, 
  product_type, master_product_id, brand_id) =>
{
return client.get(`/sales_entry/${store_id}/products/${entry_date}`,{params:{ product_type, master_product_id, brand_id }});
}

export const createSalesEntry = (store_id, entry_date,sales_entries) => {
  return client.post(`/sales_entry/${store_id}/products/${entry_date}`,{sales_entries });
}
export const searchProductsForPaymentEntry = (store_id,entry_date) =>
{
return client.get(`/payment_entry/${store_id}/payments/${entry_date}`);
}

export const createPaymentEntry = (store_id, entry_date,payment_entries) => {
  return client.post(`/payment_entry/${store_id}/payments/${entry_date}`,payment_entries );
}