import { createSelector } from 'reselect';

const getResponsesState = (state) => state.stockentry;

export const getStockEntryList = createSelector(
  getResponsesState,
  (stockentry) => stockentry.stockEntryList.data
);

export const getFetchStockEntryListQueryParams = createSelector(
  getResponsesState,
  (stockentry) => stockentry.stockEntryList.queryParams
);

export const getFetchStockEntryListStatus = createSelector(
  getResponsesState,
  (stockentry) => stockentry.stockEntryList.request_status
);

export const getProductForStockEntry = createSelector(
  getResponsesState,
  (stockentry) => stockentry.productsForStockEntry.data
);

export const getProductsForStockEntryStatus = createSelector(
  getResponsesState,
  (stockentry) => stockentry.productsForStockEntry.request_status
);


export const getSaveStockEntryStatus = createSelector(
    getResponsesState,
    (stockentry) => stockentry.savedStockEntry.request_status
  );

  export const stockEntryInitialValues = {
    stock_entries:[{
      product_id:'',
      quantity_received:'',
      quantity_damaged:'',
      purchase_price:''
    }],
  };