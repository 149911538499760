import React from 'react';
import { Alert as MUIAlert } from '@mui/material'
import clsx from 'clsx';
import { HTML_CHECK_REGEX } from '../../../utils/regex';

import { useStyles } from './alert.style';
import { Typography } from '@mui/material';


const Alert = ({ message, type }) => {
  const { classes } = useStyles();

  return (
    <MUIAlert
      variant="filled"
      color="success"
      className={clsx(classes[type], classes.alert)}
      icon={<></>}
    >
      {Array.isArray(message) ? (
        <Typography variant="body1">{message[0]}</Typography>
        /*
        message.map((msg) => (
          <Typography variant="body1">{msg}</Typography>
        ))
        */
      ) : (
        <Typography variant="body1">
          {HTML_CHECK_REGEX.test(message) ? (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            message
          )}
        </Typography>
      )}
    </MUIAlert>
  );
};

export default Alert;
