import { STOREOWNER_ACTION_TYPES } from '../actionTypes/storeowner.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  storeownerList: createPaginatedEntity({ name: "name", direction: "asc" }),
  storeowner: entityInitialState,
  savedStoreowner: entityInitialState,
  storeowners: entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const storeownerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.IN_PROGRESS:
      return {
        ...state,
        storeownerList: {
          ...state.storeownerList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.SUCCESS:
      return {
        ...state,
        storeownerList: {
          ...state.storeownerList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.storeownerList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.ERROR:
      return {
        ...state,
        storeownerList: {
          ...state.storeownerList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.RESET:
      return {
        ...state,
        storeownerList: {
          ...initialState.storeownerList
        }
      };
    /* ============================================ */
    case STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.IN_PROGRESS:
    case STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.IN_PROGRESS:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.IN_PROGRESS:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.IN_PROGRESS:
      return {
        ...state,
        savedStoreowner: {
          ...state.savedStoreowner,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.SUCCESS:
    case STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.SUCCESS:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.SUCCESS:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.SUCCESS:
      return {
        ...state,
        savedStoreowner: {
          ...state.savedStoreowner,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.ERROR:
    case STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.ERROR:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.ERROR:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.ERROR:
      return {
        ...state,
        savedStoreowner: {
          ...state.savedStoreowner,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.RESET:
    case STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.RESET:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.RESET:
    case STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.RESET:
      return {
        ...state,
        savedStoreowner: {
          ...initialState.savedStoreowner
        }
      };
    /* ============================================ */
    case STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.IN_PROGRESS:
      return {
        ...state,
        storeowner: {
          ...state.storeowner,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.SUCCESS:
      return {
        ...state,
        storeowner: {
          ...state.storeowner,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data,
         
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.ERROR:
      return {
        ...state,
        storeowner: {
          ...state.storeowner,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.RESET:
      return {
        ...state,
        storeowner: {
          ...initialState.storeowner
        }
      }
    // /* ============================================ */
    case STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.IN_PROGRESS:
      return {
        ...state,
        storeowners: {
          ...state.storeowners,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.SUCCESS:
      return {
        ...state,
        storeowners: {
          ...state.storeowners,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.ERROR:
      return {
        ...state,
        storeowners: {
          ...state.storeowners,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.RESET:
      return {
        ...state,
        storeowners: {
          ...initialState.storeowners
        }
      };
      
    /* ============================================ */
    default:
      return state;
  }
}
export default storeownerReducer;