import Cookies from 'js-cookie';

const getCookie = (name) => Cookies.get(name);

const setCookie = (name, value, expires) => {
  const attributes = {
    expires: expires || 365,
    domain: document.location.hostname
  };

  Cookies.set(name, value, attributes);
};

const removeCookie = (name) => {
  const attributes = {
    domain: document.location.hostname
  };

  Cookies.remove(name, attributes);
};

const createTitle = (title) => {

  if (title.includes('_')) {
    let newTitle = title.split('_');
    newTitle = newTitle.map(w => w[0].toUpperCase() + w.slice(1))
    newTitle = newTitle.join(" ");
    return newTitle;

  }
  else {
    return title;
  }

}


export {
  getCookie,
  setCookie,
  removeCookie,
  createTitle
};