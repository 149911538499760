/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import MarginIcon from '@mui/icons-material/Margin';

// constant
const icons = { MarginIcon };

// ==============================|| Stock Entry MENU ITEMS ||============================== //

const stockentry = {
    id: 'stockentry',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Stock Entry',
            type: 'item',
            url: '/stockentry',
            icon: icons.MarginIcon,
            breadcrumbs: false
        }
    ]
};

export default stockentry;
