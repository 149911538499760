export const HTML_CHECK_REGEX = /<\/?[a-z][\s\S]*>/i;
export const HTML_TAGS_REGEX = /(<([^>]+)>)/gi;
export const HTML_TAGS_SPACES_REGEX = /(<([^>]+)>)|(\s)|(&nbsp;)/gi;

export const LETERS_AND_ASTERIX_REGEX = /^[a-zA-Z]{1,}|(^[*]{1}$)|^(\*+[a-zA-Z]+)$/i;

export const TIME_REGEX_AMPM = /\b((1[0-2]|0?[0-9]):([0-5][0-9]) ([AaPp][Mm]))/g;
export const TIME_REGEX_FULL = /([01]\d|2[0-3]):([0-5]\d)$/g;

export const FORMAT_NUMBER_COMMAS_REGEX = /(\d)(?=(\d\d\d)+(?!\d))/g;


export const DOMAIN_REGEX = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;