import client from "./client";

export const searchWarehouses = (queryParams) => {
  return client.post(`/warehouse/search`, queryParams);
}

export const createWarehouse = (name, street_line_1, street_line_2, city, state_id, zip, contacts) => {
  return client.post(`/warehouse`, { name, street_line_1, street_line_2, city, state_id, zip, contacts });
}
export const updateWarehouse = (warehouseId, name, street_line_1, street_line_2, city, state_id, zip, contacts) => {
  return client.put(`/warehouse/${warehouseId}`, { name, street_line_1, street_line_2, city, state_id, zip, contacts });

}
export const getWarehouse = (warehouseId) => {
  return client.get(`/warehouse/${warehouseId}`);

}
export const deleteWarehouse = (warehouseId) =>{
   return client.delete(`/warehouse/${warehouseId}/delete`);
}
export const deleteWarehouses = (warehouse_uuids) =>{
  return client.post(`/warehouse/delete`, {warehouse_uuids});
}