import { createAction } from 'redux-actions';
import { STATETOPRODUCT_ACTION_TYPES } from '../actionTypes/statetoproduct.actionTypes';

export const searchStateToProductsStart = createAction(STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.START, ( stateId,brandId,masterProductId) => ({ stateId,brandId,masterProductId}));
export const searchStateToProductsInProgress = createAction(STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.IN_PROGRESS);
export const searchStateToProductsSuccess = createAction(STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.SUCCESS, (data) => ({ data }));
export const searchStateToProductsError = createAction(STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.ERROR, (error) => ({ error }));
/* ============================================ */
export const createStateToProductStart = createAction(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.START,
  ( brand,masterProductId,stateId,volumes) => ({brand,masterProductId, stateId, volumes}));
export const createStateToProductInProgress = createAction(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.IN_PROGRESS);
export const createStateToProductSuccess = createAction(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.SUCCESS, (data) => ({ data }));
export const createStateToProductError = createAction(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.ERROR, (error) => ({ error }));
export const createStateToProductReset = createAction(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.RESET);

export const fetchStateToProductStart = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.START, (id) => ({ id }));
export const fetchStateToProductInProgress = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.IN_PROGRESS);
export const fetchStateToProductSuccess = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.SUCCESS, (data) => ({ data }));
export const fetchStateToProductError = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.ERROR, (error) => ({ error }));
export const fetchStateToProductReset = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.RESET);
/* ============================================ */
export const fetchAllStateToProductsStart = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.START);
export const fetchAllStateToProductsInProgress = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.IN_PROGRESS);
export const fetchAllStateToProductsSuccess = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.SUCCESS, (data) => ({ data }));
export const fetchAllStateToProductsError = createAction(STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteStateToProductStart = createAction(STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.START, (stateId,productId) => ({ stateId,productId }));
export const deleteStateToProductInProgress = createAction(STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.IN_PROGRESS);
export const deleteStateToProductSuccess = createAction(STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.SUCCESS, (data) => ({ data }));
export const deleteStateToProductError = createAction(STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.ERROR, (error) => ({ error }));