/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import StoreIcon from '@mui/icons-material/Store';

// constant
const icons = { StoreIcon };

// ==============================|| Store List MENU ITEMS ||============================== //

const storelist = {
    id: 'storelist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Store List',
            type: 'item',
            url: '/liquorstore',
            icon: icons.StoreIcon,
            breadcrumbs: false
        }
    ]
};

export default storelist;
