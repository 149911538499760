import client from "./client";

export const searchStateToProducts = (stateId,brandId,masterProductId) => {
  return client.post(`/state/${stateId}/products`, {brand_id: brandId,master_product_id: masterProductId});
}

export const createStateToProduct = (masterProductId,stateId,volumes) => {
  return client.post(`/products/${masterProductId}/state/update/${stateId}`, volumes);
}
export const updateStateToProduct = (brandId, ) => {
  return client.put(`/stateToProduct/${brandId}`, {  });

}
export const getStateToProduct = (stateToPoductId) => {
  return client.get(`/stateToProduct/${stateToPoductId}`);

}
export const deleteStateToProduct = (stateId,productId) =>{
   return client.delete(`/state/${stateId}/product/${productId}/delete`);
}