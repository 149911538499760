
import React from 'react';
import { toast } from 'react-toastify';


import Alert from '../ui-component/extended/Alert'



class Notifier {
  alert = (type, message) => {
    toast.dismiss();
    toast(<Alert type={type} sx={{ width: '100%' }} message={message}></Alert>, {
      autoClose: 10000,
      position: toast.POSITION.TOP_CENTER
    });
  };

  notify = (type, message) => {
    toast.dismiss();
    toast(<Alert type={type} sx={{ width: '100%' }} message={message}></Alert>, {
      autoClose: 3000,
      closeButton: false
    });
  };
}

export const notifier = new Notifier();