import client from "./client";

export const searchProducts = (queryParams) => {
  return client.post(`/product/search`, queryParams);
}

export const createProduct = (name, volume_ids, type, brand_id) => {
  return client.post(`/product`, { name, volume_ids, type, brand_id });
}
export const updateProduct = (masterProductId, name, volume_ids, type, brand_id) => {
  return client.put(`/product/${masterProductId}`, { name, volume_ids, type, brand_id });

}
export const getProduct = (masterProductId) => {
  return client.get(`/product/${masterProductId}`);
}
export const getBrandMasterProduct = (brandId) => {
  return client.get(`/brand/${brandId}/masterProduct`);
}
export const getInitialQuantity = (brandId,storeId) => {
  return client.get(`/brand/${brandId}/products/${storeId}`);
}
export const getProductInfo = (productId) => {
  return client.get(`/stateToProduct/${productId}/get`);
}
export const deleteProduct = (productId) =>{
   return client.delete(`/product/${productId}/delete`);
}
export const deleteProducts = (product_uuids) =>{
  return client.post(`/product/delete`, {product_uuids});
}

