import * as actions from '../../src/store/actions/store.actions';
import * as storeActions from "../store/actions/common.actions";
import { STORE_ACTION_TYPES } from '../../src/store/actionTypes/store.actionTypes';
import * as api from '../api/store.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getAllStores,
  getFetchStoreListQueryParams, getStoreList
}
  from '../store/selectors/store.selectors';
import { getCurrentStore } from 'store/selectors/common.selectors';

/* ============================================ */
export function* searchStores({ payload: {storeOwnerId, queryParams } }) {
  try {
    yield put(actions.searchStoresInProgress());
    const paramsFromState = yield select(getFetchStoreListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStores,
      storeOwnerId,params
    );
    yield put(actions.searchStoresSuccess(data, params));

  } catch (error) {
    yield put(actions.searchStoresError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStores() {
  yield takeLatest(STORE_ACTION_TYPES.SEARCH_STORES.START, searchStores);
}
/* ============================================ */
export function* createStore({ payload: { name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments } }) {
  try {
    yield put(actions.createStoreInProgress());

    const data = yield call(
      api.createStore,
      name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments
    );
    yield call(notifier.notify, 'success', "Store added to database");
    yield put(actions.createStoreSuccess(data));
    yield put(storeActions.setSelectedStore(store_owner_id, data.uuid));
    yield put(actions.searchStoresStart(store_owner_id));

  } catch (error) {
    yield put(actions.createStoreError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStore() {
  yield takeLatest(STORE_ACTION_TYPES.CREATE_STORE.START, createStore);
}
/* ============================================ */
export function* updateStore({ payload: {  storeId, name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments } }) {

  try {
    yield put(actions.updateStoreInProgress());

    const data = yield call(
      api.updateStore,
      storeId, name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateStoreSuccess(data));
    yield put(actions.searchStoresStart(store_owner_id));

  } catch (error) {
    yield put(actions.updateStoreError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateStore() {
  yield takeLatest(STORE_ACTION_TYPES.UPDATE_STORE.START, updateStore);
}
/* ============================================ */
export function* fetchStore({ payload: { storeId } }) {
  try {
    yield put(actions.fetchStoreInProgress());
    const data = yield call(
      api.getStore,
      storeId
    );
    yield put(actions.fetchStoreSuccess(data));
  } catch (error) {
    yield put(actions.fetchStoreError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchStore() {
  yield takeLatest(STORE_ACTION_TYPES.FETCH_STORE.START, fetchStore);
}
/* ============================================ */
export function* deleteStore({ payload: { storeId,store_owner_id,isOnlyItemOnPage} }) {
  try {
    yield put(actions.deleteStoreInProgress());
    const data = yield call(
      api.deleteStore,
      storeId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStoreSuccess(data));
    const paramsFromState = yield select(getFetchStoreListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchStoresStart(store_owner_id,{...paramsFromState, page: (paramsFromState.page-1)}));
    }else{
      yield put(actions.searchStoresStart( store_owner_id ));
    }

    yield call(fetchAllStores, {payload:{'storeOwnerId':store_owner_id}})
    const currentStore = yield select(getCurrentStore); 
    // if deleted store is same as the current selected owner, then change selected store
    if(currentStore  == storeId)
    {
      const currentList = (yield select(getAllStores)).items;
      if(currentList.length == 0)
      {
        yield put(storeActions.setSelectedStore(store_owner_id, null));
      }
      else
      {
        yield put(storeActions.setSelectedStoreowner(store_owner_id, currentList[0].uuid));
      }
    }

  } catch (error) {
    console.log(error)
    yield put(actions.deleteStoreError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchDeleteStore() {
  yield takeLatest(STORE_ACTION_TYPES.DELETE_STORE.START, deleteStore);
}
export function* fetchAllStores({ payload: {storeOwnerId} }) {
  try {
    yield put(actions.fetchAllStoresInProgress());
    const paramsFromState = yield select(getFetchStoreListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchStores,
      storeOwnerId,queryParams
    );
    yield put(actions.fetchAllStoresSuccess(data));
    const currentStore = yield select(getCurrentStore);
    if(!currentStore && data.items.length > 0)
    {
      yield put(storeActions.setSelectedStore(storeOwnerId, data.items[0].uuid));
    }
  } catch (error) {
    yield put(actions.fetchAllStoresError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllStores() {
  yield takeLatest(STORE_ACTION_TYPES.FETCH_ALL_STORES.START, fetchAllStores);
}
/* ============================================ */
export function* deleteStores({ payload: { store_uuids,store_owner_id} }) {
  try {
    yield put(actions.deleteStoresInProgress());
    const data = yield call(
      api.deleteStores,
      store_uuids
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStoresSuccess(data));
    yield put(actions.searchStoresStart( store_owner_id ));
  } catch (error) {
    yield put(actions.deleteStoresError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteStores() {
  yield takeLatest(STORE_ACTION_TYPES.DELETE_STORES.START, deleteStores);
}
