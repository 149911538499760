import { createActionTypes } from '../../utils/actionTypeCreator';

export const WAREHOUSE_ACTION_TYPES = {  
  CREATE_WAREHOUSE: createActionTypes("CREATE_WAREHOUSE"),
  UPDATE_WAREHOUSE:createActionTypes("UPDATE_WAREHOUSE"),
  SEARCH_WAREHOUSES: createActionTypes('SEARCH_WAREHOUSES'),
  FETCH_WAREHOUSE:createActionTypes('FETCH_WAREHOUSE'),
  FETCH_ALL_WAREHOUSES: createActionTypes('FETCH_ALL_WAREHOUSES'),
  DELETE_WAREHOUSE: createActionTypes('DELETE_WAREHOUSE'),
  DELETE_WAREHOUSES: createActionTypes('DELETE_WAREHOUSES')
};