import { createAction } from 'redux-actions';
import { STOCK_ENTRY_ACTION_TYPES } from '../actionTypes/stockentry.actionTypes';

export const searchStockEntrysStart = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.START, (queryParams) => ({ queryParams }));
export const searchStockEntrysInProgress = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.IN_PROGRESS);
export const searchStockEntrysSuccess = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchStockEntrysError = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.ERROR, (error) => ({ error }));
/* ============================================ */

export const searchProductForStockEntryStart = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.START, 
  (store_id, warehouse_id,entry_date, product_type, master_product_id, brand_id) => ({ store_id, warehouse_id,entry_date, product_type, master_product_id, brand_id }));
export const searchProductForStockEntryInProgress = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.IN_PROGRESS);
export const searchProductForStockEntrySuccess = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.SUCCESS, (data) => ({ data }));
export const searchProductForStockEntryError = createAction(STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.ERROR, (error) => ({ error }));
/* ============================================ */

export const createStockEntryStart = createAction(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.START,
  (store_id, warehouse_id, entry_date, stock_entries) => ({ store_id, warehouse_id, entry_date, stock_entries }));
export const createStockEntryInProgress = createAction(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.IN_PROGRESS);
export const createStockEntrySuccess = createAction(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.SUCCESS, (data) => ({ data }));
export const createStockEntryError = createAction(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.ERROR, (error) => ({ error }));
export const createStockEntryReset = createAction(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.RESET);
