import client from "./client";

export const searchStores = (storeOwnerId,queryParams) => {
    return client.post(`/store/${storeOwnerId}/search`, queryParams);
}

export const createStore = (name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id,  payments) => {
    return client.post(`/store`, { name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments });
}
export const updateStore = ( storeId, name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments) => {
    return client.put(`/store/${storeId}`, {  name, store_type, street_line_1, street_line_2, city, state_id, zip, store_owner_id, payments });

}
export const getStore = (storeId) => {
    return client.get(`/store/${storeId}`);
}
export const deleteStore = (storeId) =>{
     return client.delete(`/store/${storeId}/delete`);
}
export const deleteStores = (store_uuids) =>{
     return client.post(`/store/delete`,{store_uuids});
}