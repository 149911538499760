import { createAction } from 'redux-actions';
import { PRODUCT_TO_STATE_ACTION_TYPES } from '../actionTypes/productToState.actionTypes';

export const searchProductToStatesStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.START, (masterProductId,stateId) => ({  masterProductId,stateId }));
export const searchProductToStatesInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.IN_PROGRESS);
export const searchProductToStatesSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.SUCCESS, (data) => ({ data }));
export const searchProductToStatesError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.ERROR, (error) => ({ error }));
/* ============================================ */
export const createProductToStateStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.START,
  (stateId, masterProductId, volumes) => ({ stateId, masterProductId, volumes }));
export const createProductToStateInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.IN_PROGRESS);
export const createProductToStateSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.SUCCESS, (data) => ({ data }));
export const createProductToStateError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.ERROR, (error) => ({ error }));
export const createProductToStateReset = createAction(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.RESET);
/* ============================================ */
export const updateProductToStateStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.START,
  (brandId, name, distillery, is_domestic) => ({ brandId, name, distillery, is_domestic }));
export const updateProductToStateInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.IN_PROGRESS);
export const updateProductToStateSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.SUCCESS, (data) => ({ data }));
export const updateProductToStateError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.ERROR, (error) => ({ error }));
export const updateProductToStateReset = createAction(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.RESET);
/* ============================================ */
export const deleteProductToStateStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.START, (stateId, productId) => ({ stateId, productId }));
export const deleteProductToStateInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.IN_PROGRESS);
export const deleteProductToStateSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.SUCCESS, (data) => ({ data }));
export const deleteProductToStateError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.ERROR, (error) => ({ error }));

export const fetchProductToStateStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.START, (id) => ({ id }));
export const fetchProductToStateInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.IN_PROGRESS);
export const fetchProductToStateSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.SUCCESS, (data) => ({ data }));
export const fetchProductToStateError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.ERROR, (error) => ({ error }));
export const fetchProductToStateReset = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.RESET);
/* ============================================ */
export const fetchAllProductToStatesStart = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.START);
export const fetchAllProductToStatesInProgress = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.IN_PROGRESS);
export const fetchAllProductToStatesSuccess = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.SUCCESS, (data) => ({ data }));
export const fetchAllProductToStatesError = createAction(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.ERROR, (error) => ({ error }));
