import { createAction } from 'redux-actions';
import { STOREUSER_ACTION_TYPES } from '../actionTypes/storeuser.actionTypes';

export const searchStoreusersStart = createAction(STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.START, (store_owner_id, queryParams) => ({ store_owner_id, queryParams }));
export const searchStoreusersInProgress = createAction(STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.IN_PROGRESS);
export const searchStoreusersSuccess = createAction(STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchStoreusersError = createAction(STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.ERROR, (error) => ({ error }));
/* ============================================ */
export const createStoreuserStart = createAction(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.START,
  (store_owner_id, name, password, email, phone, address, profile_type, stores) => ({ store_owner_id, name, password, email, phone, address, profile_type, stores }));
export const createStoreuserInProgress = createAction(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.IN_PROGRESS);
export const createStoreuserSuccess = createAction(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.SUCCESS, (data) => ({ data }));
export const createStoreuserError = createAction(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.ERROR, (error) => ({ error }));
export const createStoreuserReset = createAction(STOREUSER_ACTION_TYPES.CREATE_STOREUSER.RESET);
/* ============================================ */
export const updateStoreuserStart = createAction(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.START,
  (userId, name, password, email, phone, address, profile_type, stores, store_owner_id) => ({ userId, name, password, email, phone, address, profile_type, stores, store_owner_id }));
export const updateStoreuserInProgress = createAction(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.IN_PROGRESS);
export const updateStoreuserSuccess = createAction(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.SUCCESS, (data) => ({ data }));
export const updateStoreuserError = createAction(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.ERROR, (error) => ({ error }));
export const updateStoreuserReset = createAction(STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.RESET);

export const deleteStoreUserStart = createAction(STOREUSER_ACTION_TYPES.DELETE_STOREUSER.START, (userId, storeownerId,isOnlyItemOnPage) => ({ userId, storeownerId, isOnlyItemOnPage }));
export const deleteStoreUserInProgress = createAction(STOREUSER_ACTION_TYPES.DELETE_STOREUSER.IN_PROGRESS);
export const deleteStoreUserSuccess = createAction(STOREUSER_ACTION_TYPES.DELETE_STOREUSER.SUCCESS, (data) => ({ data }));
export const deleteStoreUserError = createAction(STOREUSER_ACTION_TYPES.DELETE_STOREUSER.ERROR, (error) => ({ error }));

export const fetchStoreuserStart = createAction(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.START, (storeStaffId) => ({ storeStaffId }));
export const fetchStoreuserInProgress = createAction(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.IN_PROGRESS);
export const fetchStoreuserSuccess = createAction(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.SUCCESS, (data) => ({ data }));
export const fetchStoreuserError = createAction(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.ERROR, (error) => ({ error }));
export const fetchStoreuserReset = createAction(STOREUSER_ACTION_TYPES.FETCH_STOREUSER.RESET);
