import { STATETOPRODUCT_ACTION_TYPES } from '../actionTypes/statetoproduct.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  stateToProductList: createPaginatedEntity({ name: "name", direction: "asc" }),
  stateToProduct: entityInitialState,
  stateToProducts: entityInitialState,
  savedStateToProduct: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const stateToProductReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.IN_PROGRESS:
      return {
        ...state,
        stateToProductList: {
          ...state.stateToProductList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.SUCCESS:
      return {
        ...state,
        stateToProductList: {
          ...state.stateToProductList,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.ERROR:
      return {
        ...state,
        stateToProductList: {
          ...state.stateToProductList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.RESET:
      return {
        ...state,
        stateToProductList: {
          ...initialState.stateToProductList
        }
      };
    /* ============================================ */
    case STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.IN_PROGRESS:
    case STATETOPRODUCT_ACTION_TYPES.UPDATE_STATETOPRODUCT.IN_PROGRESS:
    case STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.IN_PROGRESS:

      return {
        ...state,
        savedStateToProduct: {
          ...state.savedStateToProduct,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.SUCCESS:
    case STATETOPRODUCT_ACTION_TYPES.UPDATE_STATETOPRODUCT.SUCCESS:
    case STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.SUCCESS:

      return {
        ...state,
        savedStateToProduct: {
          ...state.savedStateToProduct,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.ERROR:
    case STATETOPRODUCT_ACTION_TYPES.UPDATE_STATETOPRODUCT.ERROR:
    case STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.ERROR:

      return {
        ...state,
        savedStateToProduct: {
          ...state.savedStateToProduct,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.RESET:
    case STATETOPRODUCT_ACTION_TYPES.UPDATE_STATETOPRODUCT.RESET:
    case STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.RESET:

      return {
        ...state,
        savedStateToProduct: {
          ...initialState.savedStateToProduct
        }
      };
    /* ============================================ */
    case STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.IN_PROGRESS:
      return {
        ...state,
        stateToProduct: {
          ...state.stateToProduct,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.SUCCESS:
      return {
        ...state,
        stateToProduct: {
          ...state.stateToProduct,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.ERROR:
      return {
        ...state,
        stateToProduct: {
          ...state.stateToProduct,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.RESET:
      return {
        ...state,
        stateToProduct: {
          ...initialState.stateToProduct
        }
      }
    // /* ============================================ */
    case STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.IN_PROGRESS:
      return {
        ...state,
        stateToProducts: {
          ...state.stateToProducts,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.SUCCESS:
      return {
        ...state,
        stateToProducts: {
          ...state.stateToProducts,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.ERROR:
      return {
        ...state,
        stateToProducts: {
          ...state.stateToProducts,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.RESET:
      return {
        ...state,
        stateToProducts: {
          ...initialState.stateToProducts
        }
      };
    /* ============================================ */
    default:
      return state;
  }
}
export default stateToProductReducer;