import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, _params, classes) => ({
    root: {
        padding: 0,
        minHeight: 'auto',
        backgroundColor: 'transparent',
        minWidth: '520px',
        maxWidth: '800px',
        margin: `${theme.spacing(1)} auto`
      },
      body: {
        padding: 0
      },
    
      container: {
        width: 'auto'
      },
    
      center: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
      }
  })
);
