import { createSelector } from 'reselect';

const getResponsesState = (state) => state.store;

/* ============================================ */
export const getStoreList = createSelector(
  getResponsesState,
  (store) => store.storeList.data
);

export const getFetchStoreListQueryParams = createSelector(
  getResponsesState,
  (store) => store.storeList.queryParams
);

export const getFetchStoreListStatus = createSelector(
  getResponsesState,
  (store) => store.storeList.request_status
);
export const getSaveStoreStatus = createSelector(
  getResponsesState,
  (store) => store.savedStore.request_status
);
export const getStore = createSelector(
  getResponsesState,
  (store) => store.store.data
);
export const getAllStores = createSelector(
  getResponsesState,
  (store) => store.stores.data
);
export const getEditStoreInitialValues = createSelector(
  getStore,
  (store) => {
    if (store) {
      return {
        name: store.name,
        store_type: store.store_type,
        street_line_1: store. street_line_1 || '',
        street_line_2: store.street_line_2 || '',
        city:store.city || '',
        state_id:store.state.uuid,
        zip:store.zip || '',
        store_owner_id:store.store_owner.uuid,
        payments:store.payment_methods,
      };
    }
    return storeInitialValues;
  }
);

export const storeInitialValues = {
  name: '',
  store_type: '',
  street_line_1: '',
  street_line_2:'',
  city:'',
  state_id:'',
  zip:'',
  store_owner_id:'',
  payments:[{
    name:'',
    type:''
  }],
 
};
