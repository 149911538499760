import * as actions from '../../src/store/actions/statetoproduct.actions';
import { STATETOPRODUCT_ACTION_TYPES } from '../../src/store/actionTypes/statetoproduct.actionTypes';
import * as api from '../api/statetoproduct.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchStateToProductListQueryParams
}
  from '../store/selectors/statetoproduct.selectors';

/* ============================================ */
export function* searchStateToProducts({ payload: { stateId, brandId, masterProductId } }) {
  try {
    yield put(actions.searchStateToProductsInProgress());

    const data = yield call(
      api.searchStateToProducts,
      stateId
      , brandId, masterProductId
    );
    yield put(actions.searchStateToProductsSuccess(data));
  } catch (error) {
    yield put(actions.searchStateToProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStateToProducts() {
  yield takeLatest(STATETOPRODUCT_ACTION_TYPES.SEARCH_STATETOPRODUCTS.START, searchStateToProducts);
}
/* ============================================ */
export function* createStateToProduct({ payload: { brand, masterProductId, stateId, volumes } }) {
  try {
    yield put(actions.createStateToProductInProgress());

    const data = yield call(
      api.createStateToProduct,
      masterProductId,
      stateId,
      volumes
    );
    yield call(notifier.notify, 'success', "Product has been assigned to the state");
    yield put(actions.createStateToProductSuccess(data));
  } catch (error) {
    yield put(actions.createStateToProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStateToProduct() {
  yield takeLatest(STATETOPRODUCT_ACTION_TYPES.CREATE_STATETOPRODUCT.START, createStateToProduct);
}

/* ============================================ */
export function* fetchStateToProduct({ payload: { id } }) {
  try {
    yield put(actions.fetchStateToProductInProgress());
    const data = yield call(
      api.getStateToProduct,
      id
    );
    yield put(actions.fetchStateToProductSuccess(data));
  } catch (error) {
    yield put(actions.fetchStateToProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchStateToProduct() {
  yield takeLatest(STATETOPRODUCT_ACTION_TYPES.FETCH_STATETOPRODUCT.START, fetchStateToProduct);
}
export function* fetchAllStateToProducts() {
  try {
    yield put(actions.fetchAllStateToProductsInProgress());
    const paramsFromState = yield select(getFetchStateToProductListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchStateToProducts,
      queryParams
    );
    yield put(actions.fetchAllStateToProductsSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllStateToProductsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllStateToProducts() {
  yield takeLatest(STATETOPRODUCT_ACTION_TYPES.FETCH_ALL_STATETOPRODUCTS.START, fetchAllStateToProducts);
}
/* ============================================ */
export function* deleteStateToProduct({ payload: { stateId, productId } }) {
  try {
    yield put(actions.deleteStateToProductInProgress());
    const data = yield call(
      api.deleteStateToProduct,
      stateId, productId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStateToProductSuccess(data));
    yield put(actions.searchStateToProductsStart(stateId));

  } catch (error) {
    yield put(actions.deleteStateToProductError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchDeleteStateToProduct() {
  yield takeLatest(STATETOPRODUCT_ACTION_TYPES.DELETE_STATETOPRODUCT.START, deleteStateToProduct);
}

