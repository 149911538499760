/* eslint-disable */

import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Brandlist = Loadable(lazy(() => import('views/brand/BrandList')));
const CreateBrand = Loadable(lazy(() => import('views/brand/CreateBrand')));
const EditBrand = Loadable(lazy(() => import('views/brand/EditBrand')));
const Productlist = Loadable(lazy(() => import('views/product/ProductList')));
const CreateProduct = Loadable(lazy(() => import('views/product/CreateProduct')));
const EditProduct = Loadable(lazy(() => import('views/product/EditProduct')));
const ProductToStateList = Loadable(lazy(() => import('views/product/ProductToStateList')));

const Statelist = Loadable(lazy(() => import('views/states/StatesList')));
const Storelist = Loadable(lazy(() => import('views/liquorstore/StoreList')));
const StoreUserlist = Loadable(lazy(() => import('views/userlist/StoreUserList')));
const Warehouselist = Loadable(lazy(() => import('views/warehouse/WarehouseList')));
const Storeownerlist = Loadable(lazy(() => import('views/storeowner/StoreownerList')));
const MainreportDefault = Loadable(lazy(() => import('views/mainreport/Default')));
const StockList = Loadable(lazy(() => import('views/stocklist/StockList')));

const AssignedProductList = Loadable(lazy(() => import('views/states/AssignedProductList')));
const CreateStoreowner = Loadable(lazy(() => import('views/storeowner/CreateStoreowner')));
const EditStoreowner = Loadable(lazy(() => import('views/storeowner/EditStoreowner')));
const CreateStore = Loadable(lazy(() => import('views/liquorstore/CreateStore')));
const EditStore = Loadable(lazy(() => import('views/liquorstore/EditStore')));
const Createstoreuser = Loadable(lazy(() => import('views/userlist/CreateStoreUser')));
const Editstoreuser = Loadable(lazy(() => import('views/userlist/EditStoreUser')));
const Createwarehouse = Loadable(lazy(() => import('views/warehouse/CreatewarehouseForm')));
const Editwarehouse = Loadable(lazy(() => import('views/warehouse/EditwarehouseForm')));
const StockEntry = Loadable(lazy(() => import('views/stockentry/StockEntry')));
const MakeEntry = Loadable(lazy(() => import('views/stockentry/MakeEntry')));
const SalesEntry = Loadable(lazy(() => import('views/salesentry/SalesEntry')));
const SalesMakeEntry = Loadable(lazy(() => import('views/salesentry/SalesMakeEntry')));
const Paymentmethodlist = Loadable(lazy(() => import('views/paymentmethods/PaymentMethodList')));
const CreatePaymentMethod = Loadable(lazy(() => import('views/paymentmethods/CreatePaymentMethod')));
const EditPaymentMethod = Loadable(lazy(() => import('views/paymentmethods/EditPaymentMethod')));
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));


const AccountSettingPage = Loadable(lazy(() => import('views/utilities/AccountSettingPage')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Report = Loadable(lazy(() => import('views/mainreport/Report')));
const Component1 = Loadable(lazy(() => import('views/mainreport/Component1')));
const Component2 = Loadable(lazy(() => import('views/mainreport/Component2')));
const Component3 = Loadable(lazy(() => import('views/mainreport/Component3')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'brands',
      children: [
        {
          path: '',
          element: <Brandlist />
        },
        {
          path: 'new',
          element: <CreateBrand />
        },
        {
          path: ':brandId/edit',
          element: <EditBrand />
        },
      ]
    },
    {
      path: 'paymentmethods',
      children: [
        {
          path: '',
          element: <Paymentmethodlist />
        }
        ,
        {
          path: 'createpaymentmethod',
          element: <CreatePaymentMethod />
        },
        {
          path: 'editpaymentmethod',
          element: <EditPaymentMethod />
        }
      ]
    },
    {
      path: 'products',
      children: [
        {
          path: '',
          element: <Productlist />
        },
        {
          path: 'new',
          element: <CreateProduct />
        },
        {
          path: ':masterProductId/edit',
          element: <EditProduct />
        },
        {
          path: ':masterProductId/assignedstates',
          element: <ProductToStateList />
        }
      ]
    },
    {
      path: 'states',
      children: [
        {
          path: '',
          element: <Statelist />
        },
        {
          path: ':stateId/assignedproduct',
          element: <AssignedProductList />
        }
      ]
    },

    {
      path: 'storeowner',
      children: [
        {
          path: '',
          element: <Storeownerlist />
        }
        ,

        {
          path: 'createstoreowner',
          element: <CreateStoreowner />
        },
        {
          path: ':StoreownerId/edit',
          element: <EditStoreowner />
        }
      ]
    },
    {
      path: 'user',
      children: [
        {
          path: 'account-profile',
          element: <AccountSettingPage />
        },
      ]
    },
    {
      path: 'liquorstore',
      children: [
        {
          path: '',
          element: <Storelist />
        },
        {
          path: 'createstore',
          element: <CreateStore />
        },
        {
          path: ':storeId/editstore',
          element: <EditStore />
        }
      ]
    },
    {
      path: 'userlist',
      children: [
        {
          path: '',
          element: <StoreUserlist />
        }
        ,
        {
          path: 'createuser',
          element: <Createstoreuser />
        }
        ,
        {
          path: ':storeuserId/edituser',
          element: <Editstoreuser />
        },
        {
          path: 'mainreport',
          children: [
            {
              path: 'default',
              element: <MainreportDefault />
            }
          ]
        },
      ]
    }
    ,
    {
      path: 'warehouse',
      children: [
        {
          path: '',
          element: <Warehouselist />
        }
        ,
        {
          path: 'createwarehouse',
          element: <Createwarehouse />
        },
        {
          path: ':warehouseId/editwarehouse',
          element: <Editwarehouse />
        }
      ]
    },
    {
      path: 'stocklist',
      children: [
        {
          path: '',
          element: <StockList />
        }
      ]
    },
    {
      path: 'stockentry',
      children: [
        {
          path: '',
          element: <StockEntry />
        },
        {
          path: 'makeentry',
          element: <MakeEntry />
        }
      ]
    },
    {
      path: 'report',
      children: [
        {
          path: '',
          element: <Report />,
          children: [
            {
              path: 'component1',
              element: <Component1 />
            },
            {
              path: 'component2',
              element: <Component2 />
            },
            {
              path: 'component3',
              element: <Component3 />
            },
          ]

        },

      ]
    },
    {
      path: 'salesentry',
      children: [
        {
          path: '',
          element: <SalesEntry />
        },
        {
          path: 'salesmakeentry',
          element: <SalesMakeEntry />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    }
  ]
};

export default MainRoutes;
