import { createStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from '../sagas/root.saga'

// ==============================|| REDUX - MAIN STORE ||============================== //

//const store = createStore(reducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(sagaMiddleware)
  },
});

store.runSaga = sagaMiddleware.run(rootSaga);
const persister = 'liquor-road';

export { store, persister };
