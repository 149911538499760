/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';

// constant
const icons = { BrandingWatermarkIcon };

// ==============================|| Brand List MENU ITEMS ||============================== //

const brandlist = {
  id: 'brandlist',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Brand List',
      type: 'item',
      url: '/brands',
      icon: icons.BrandingWatermarkIcon,
      breadcrumbs: false
    }
  ]
};

export default brandlist;
