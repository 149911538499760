import { connect, useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions/ui.actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { getCurrentUser, getFetchAccountStatus, getLogoutStatus } from 'store/selectors/auth.selectors';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { fetchAccountStart, logoutReset } from 'store/actions/auth.actions';
import { ACTION_STATUS, isLoading } from 'utils/constants';
import Loader from 'ui-component/Loader';
import { fetchAccount } from 'sagas/auth.saga';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = (props) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const { currentUser } = props;

  const loading = isLoading(props.fetchAccountStatus);

  useEffect(() => {
    props.fetchAccount();
  },[]);

  useEffect(() => {
    if(props.logoutStatus == ACTION_STATUS.SUCCESS)
    {
      props.resetLogout();
      navigate('/login');
    }
  },[props.logoutStatus]);

  useEffect(() => {
    if (currentUser != null) {
      if (i18n.language != currentUser.preferred_language) {
        i18n.changeLanguage(currentUser.preferred_language);
      }
      if (currentUser.force_change_password === 1) {
        navigate("/reset-password");
      }
    }
  }, [currentUser, navigate]);

  return (
    <>
    {loading && <Loader />}
    { currentUser && 
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
    }
    </>
  );
};


const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  fechAccountStatus: getFetchAccountStatus(state),
  logoutStatus: getLogoutStatus(state)
})

export const mapDispatchToProps = {
  fetchAccount: fetchAccountStart,
  resetLogout: logoutReset
};


export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);

