import { createSelector } from 'reselect';
import { loginSuccess } from '../actions/auth.actions';

const getResponsesState = (state) =>
  state.auth;

export const getCurrentUser = createSelector(
  getResponsesState,
  (auth) => auth.fetchAccountResponse.data
);

export const getLoginData = createSelector(
  getResponsesState,
  (auth) => auth.loginResponse.data
);
export const getLoginStatus = createSelector(
  getResponsesState,
  (auth) => auth.loginResponse.request_status
);

export const getLogoutStatus = createSelector(
  getResponsesState,
  (auth) => auth.logoutResponse.request_status
);


export const getForgotPasswordStatus = createSelector(
  getResponsesState,
  (auth) => auth.forgotPasswordResponse.request_status
);

export const getResetPasswordStatus = createSelector(
  getResponsesState,
  (auth) => auth.resetPasswordResponse.request_status
);
export const getChangePasswordStatus = createSelector(
  getResponsesState,
  (auth) => auth.changePasswordResponse.request_status
);

export const getUpdateStatus = createSelector(
  getResponsesState,
  (auth) => auth.updateResponse.request_status
)


export const getFetchAccountStatus = createSelector(
  getResponsesState,
  (auth) => auth.fetchAccountResponse.request_status
)