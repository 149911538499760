import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from './notificationToast.style';

export const NotificationToast = () => {
  const { classes } = useStyles();
  return (
    <ToastContainer
      draggable={false}
      closeButton={<CloseIcon />}
      hideProgressBar
      toastClassName={classes.root}
      className={clsx(classes.container, classes.center)}
      position={toast.POSITION.TOP_CENTER}
      bodyClassName={classes.body}
    />
  );
};

export default NotificationToast;
