/* TAKES IN A STRING 'FETCH_YOUR_THINGY'
FOR EXAMPLE AND WILL OUTPUT AN OBJECT OF MAPPED ACTION TYPES FOR YOU TO USE
HOPEFULLY REDUCING BOILER PLATE! */

export const createActionTypes = (actionTypeName) => ({
  START: `${actionTypeName}_START`,
  IN_PROGRESS: `${actionTypeName}_IN_PROGRESS`,
  SUCCESS: `${actionTypeName}_SUCCESS`,
  ERROR: `${actionTypeName}_ERROR`,
  RESET: `${actionTypeName}_RESET`
});