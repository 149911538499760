import { createActionTypes } from '../../utils/actionTypeCreator';

export const STORE_ACTION_TYPES = {  
  CREATE_STORE: createActionTypes("CREATE_STORE"),
  UPDATE_STORE:createActionTypes("UPDATE_STORE"),
  SEARCH_STORES: createActionTypes('SEARCH_STORES'),
  FETCH_STORE:createActionTypes('FETCH_STORE'),
  FETCH_ALL_STORES: createActionTypes('FETCH_ALL_STORES'),
  DELETE_STORE: createActionTypes('DELETE_STORE'),
  DELETE_STORES: createActionTypes('DELETE_STORES')
};