import moment from "moment";

export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

export const ITEMS_PER_PAGE = 10;
export const ROWS_PER_PAGE = [10, 25, 50, 100];

export const ACTION_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

export const entityInitialState = {
  data: null,
  request_status: ACTION_STATUS.NOT_STARTED,
  error: null,
};

export const isLoading = (...loadingProps) =>
  loadingProps.some((prop) => prop === ACTION_STATUS.IN_PROGRESS);

export const isLoaded = (...loadingProps) =>
  loadingProps.every((prop) => prop === ACTION_STATUS.SUCCESS);

export const getDefaultPagination = (currentUser,filters, sortOrder, rowsPerPage) => {
  return {
    searchText: null,
    filters: filters,
    page: 0,
    rowsPerPage: rowsPerPage || (currentUser.role == 'admin' ? 50 : 10),
    sortOrder: sortOrder || {},
  };
}
// {store_owner_id,store_id,brand_id,master_product_id,type}
export const createPaginatedEntity = (sortOrder, rowsPerPage) => {
  return {
    data: {
      items: null,
      total: 0,
    },
    queryParams: getDefaultPagination(sortOrder, rowsPerPage),
    request_status: ACTION_STATUS.NOT_STARTED,
    error: null,
  };
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";

export const getAcademicYearOptions = () => {
  const curYear = moment().year();
  const curMonth = moment().month();
  const options = [];
  for (let i = 2021; i <= curYear; i++) {
    if (i == curYear) {
      if (curMonth >= 3) {
        options.push(i + "-" + (i + 1).toString().substring(2));
      }
    } else {
      options.push(i + "-" + (i + 1).toString().substring(2));
    }
  }
  return options;
};

export const getTestsheetLink = (testsheet, currentUser) => {
  if (currentUser.role == "student") {
    return testsheet.responder_url;
  }
  return `https://docs.google.com/forms/d/${testsheet.google_form_id}/edit`
}

export const getHomeUrl = (currentUser) => {
  if (currentUser.force_change_password === 1) {
    return '/reset-password';
  }
  if (currentUser.role == "student") {
    return `/app/student/${currentUser.uuid}/summary`;
  }
  return `/app`;
}

export const getDate = (dateStr, format) => {
  return moment(dateStr, format);
}

export const PRODUCT_TYPES = [
  "Beer",
  "Whisky",
  "Wine",
  "Vodka",
  "Gin",
  "Rum"
];

export const STORE_TYPES = [
  "IFML",
  "Model Shop",
  "Beer",
  "Premium"
];