import * as actions from '../../src/store/actions/brand.actions';
import { BRAND_ACTION_TYPES } from '../../src/store/actionTypes/brand.actionTypes';
import * as api from '../api/brand.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchBrandListQueryParams
}
  from '../store/selectors/brand.selectors';

/* ============================================ */
export function* searchBrands({ payload: { queryParams } }) {
  try {
    yield put(actions.searchBrandsInProgress());
    const paramsFromState = yield select(getFetchBrandListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchBrands,
      params

    );
    yield put(actions.searchBrandsSuccess(data, params));
  } catch (error) {
    yield put(actions.searchBrandsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchBrands() {
  yield takeLatest(BRAND_ACTION_TYPES.SEARCH_BRANDS.START, searchBrands);
}
/* ============================================ */
export function* createBrand({ payload: { name, distillery, is_domestic } }) {
  try {
    yield put(actions.createBrandInProgress());

    const data = yield call(
      api.createBrand,
      name,
      distillery,
      is_domestic
    );
    yield call(notifier.notify, 'success', "Brand added to database");
    yield put(actions.createBrandSuccess(data));
    yield put(actions.searchBrandsStart());

  } catch (error) {
    yield put(actions.createBrandError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateBrand() {
  yield takeLatest(BRAND_ACTION_TYPES.CREATE_BRAND.START, createBrand);
}
/* ============================================ */
export function* updateBrand({ payload: { brandId, name, distillery, is_domestic } }) {

  try {
    yield put(actions.updateBrandInProgress());

    const data = yield call(
      api.updateBrand,
      brandId,
      name,
      distillery,
      is_domestic
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateBrandSuccess(data));
    yield put(actions.searchBrandsStart());

  } catch (error) {
    yield put(actions.updateBrandError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateBrand() {
  yield takeLatest(BRAND_ACTION_TYPES.UPDATE_BRAND.START, updateBrand);
}
/* ============================================ */
export function* deleteBrand({ payload: { brandId, isOnlyItemOnPage } }) {
  try {
    yield put(actions.deleteBrandInProgress());

    const data = yield call(
      api.deleteBrand,
      brandId
    );
    yield call(notifier.notify, 'success', "Brand deleted successfully");
    yield put(actions.deleteBrandSuccess(data));
    const paramsFromState = yield select(getFetchBrandListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchBrandsStart({...paramsFromState, page: (paramsFromState.page-1)}));
    }
    else
    {
      yield put(actions.searchBrandsStart());
    }
  } catch (error) {
    yield put(actions.deleteBrandError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchDeleteBrand() {
  yield takeLatest(BRAND_ACTION_TYPES.DELETE_BRAND.START, deleteBrand);
}
/* ============================================ */
export function* fetchBrand({ payload: { id } }) {
  try {
    yield put(actions.fetchBrandInProgress());
    const data = yield call(
      api.getBrand,
      id
    );
    yield put(actions.fetchBrandSuccess(data));
  } catch (error) {
    yield put(actions.fetchBrandError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchBrand() {
  yield takeLatest(BRAND_ACTION_TYPES.FETCH_BRAND.START, fetchBrand);
}
export function* fetchAllBrands() {
  try {
    yield put(actions.fetchAllBrandsInProgress());
    const paramsFromState = yield select(getFetchBrandListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchBrands,
      queryParams
    );
    yield put(actions.fetchAllBrandsSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllBrandsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllBrands() {
  yield takeLatest(BRAND_ACTION_TYPES.FETCH_ALL_BRANDS.START, fetchAllBrands);
}
/* ============================================ */
export function* deleteBrands({ payload: { brand_uuids } }) {
  try {
    yield put(actions.deleteBrandsInProgress());
    const data = yield call(
      api.deleteBrands,
      brand_uuids
    );
    yield call(notifier.notify, 'success', "Brands deleted successfully");
    yield put(actions.deleteBrandsSuccess(data));
    yield select(getFetchBrandListQueryParams);
      yield put(actions.searchBrandsStart());
  } catch (error) {
    yield put(actions.deleteBrandsError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchDeleteBrands() {
  yield takeLatest(BRAND_ACTION_TYPES.DELETE_BRANDS.START, deleteBrands);
}