import { STOCK_LIST_ACTION_TYPES } from '../actionTypes/stocklist.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  stockList: createPaginatedEntity({ name: "name", direction: "asc" }),
};

/* eslint-disable default-case, no-param-reassign */
const stockListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STOCK_LIST_ACTION_TYPES.SEARCH_STOCK_LIST.IN_PROGRESS:
      return {
        ...state,
        stockList: {
          ...state.stockList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOCK_LIST_ACTION_TYPES.SEARCH_STOCK_LIST.SUCCESS:
      return {
        ...state,
        stockList: {
          ...state.stockList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.stockList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STOCK_LIST_ACTION_TYPES.SEARCH_STOCK_LIST.ERROR:
      return {
        ...state,
        stockList: {
          ...state.stockList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOCK_LIST_ACTION_TYPES.SEARCH_STOCK_LIST.RESET:
      return {
        ...state,
        stockList: {
          ...initialState.stockList
        }
      };
    
    /* ============================================ */
    default:
      return state;
  }
}
export default stockListReducer;