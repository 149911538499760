import { createAction } from 'redux-actions';
import { WAREHOUSE_ACTION_TYPES } from '../actionTypes/warehouse.actionTypes';

export const searchWarehousesStart = createAction(WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.START, (queryParams) => ({ queryParams }));
export const searchWarehousesInProgress = createAction(WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.IN_PROGRESS);
export const searchWarehousesSuccess = createAction(WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchWarehousesError = createAction(WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.ERROR, (error) => ({ error }));
/* ============================================ */
export const createWarehouseStart = createAction(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.START,
  (name, street_line_1, street_line_2, city, state_id, zip, contacts) => ({ name, street_line_1, street_line_2, city, state_id, zip, contacts }));
export const createWarehouseInProgress = createAction(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.IN_PROGRESS);
export const createWarehouseSuccess = createAction(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.SUCCESS, (data) => ({ data }));
export const createWarehouseError = createAction(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.ERROR, (error) => ({ error }));
export const createWarehouseReset = createAction(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.RESET);
/* ============================================ */
export const updateWarehouseStart = createAction(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.START,
  (warehouseId, name, street_line_1, street_line_2, city, state_id, zip, contacts) => ({ warehouseId, name, street_line_1, street_line_2, city, state_id, zip, contacts }));
export const updateWarehouseInProgress = createAction(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.IN_PROGRESS);
export const updateWarehouseSuccess = createAction(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.SUCCESS, (data) => ({ data }));
export const updateWarehouseError = createAction(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.ERROR, (error) => ({ error }));
export const updateWarehouseReset = createAction(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.RESET);
/* ============================================ */
export const deleteWarehouseStart = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.START, (warehouseId,isOnlyItemOnPage) => ({warehouseId,isOnlyItemOnPage }));
export const deleteWarehouseInProgress = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.IN_PROGRESS);
export const deleteWarehouseSuccess = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.SUCCESS, (data) => ({ data }));
export const deleteWarehouseError = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.ERROR, (error) => ({ error }));
/* ============================================ */
export const fetchWarehouseStart = createAction(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.START, (warehouseId) => ({warehouseId }));
export const fetchWarehouseInProgress = createAction(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.IN_PROGRESS);
export const fetchWarehouseSuccess = createAction(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.SUCCESS, (data) => ({ data }));
export const fetchWarehouseError = createAction(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.ERROR, (error) => ({ error }));
export const fetchWarehouseReset = createAction(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.RESET);
/* ============================================ */
export const fetchAllWarehousesStart = createAction(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.START);
export const fetchAllWarehousesInProgress = createAction(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.IN_PROGRESS);
export const fetchAllWarehousesSuccess = createAction(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.SUCCESS, (data) => ({ data }));
export const fetchAllWarehousesError = createAction(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.ERROR, (error) => ({ error }));
export const fetchAllWarehousesReset = createAction(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.RESET);
/* ============================================ */
export const deleteWarehousesStart = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.START, (warehouse_uuids) => ({warehouse_uuids}));
export const deleteWarehousesInProgress = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.IN_PROGRESS);
export const deleteWarehousesSuccess = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.SUCCESS, (data) => ({ data }));
export const deleteWarehousesError = createAction(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.ERROR, (error) => ({ error }));