import { STOREUSER_ACTION_TYPES } from '../actionTypes/storeuser.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  storeuserList: createPaginatedEntity({ name: "name", direction: "asc" }),
  storeuser: entityInitialState,
  savedStoreuser: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const storeuserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.IN_PROGRESS:
      return {
        ...state,
        storeuserList: {
          ...state.storeuserList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.SUCCESS:
      return {
        ...state,
        storeuserList: {
          ...state.storeuserList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.storeuserList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.ERROR:
      return {
        ...state,
        storeuserList: {
          ...state.storeuserList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREUSER_ACTION_TYPES.SEARCH_STOREUSERS.RESET:
      return {
        ...state,
        storeuserList: {
          ...initialState.storeuserList
        }
      };
    /* ============================================ */
    case STOREUSER_ACTION_TYPES.CREATE_STOREUSER.IN_PROGRESS:
    case STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.IN_PROGRESS:
    case STOREUSER_ACTION_TYPES.DELETE_STOREUSER.IN_PROGRESS:
      return {
        ...state,
        savedStoreuser: {
          ...state.savedStoreuser,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREUSER_ACTION_TYPES.CREATE_STOREUSER.SUCCESS:
    case STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.SUCCESS:
    case STOREUSER_ACTION_TYPES.DELETE_STOREUSER.SUCCESS:
      return {
        ...state,
        savedStoreuser: {
          ...state.savedStoreuser,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STOREUSER_ACTION_TYPES.CREATE_STOREUSER.ERROR:
    case STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.ERROR:
    case STOREUSER_ACTION_TYPES.DELETE_STOREUSER.ERROR:
      return {
        ...state,
        savedStoreuser: {
          ...state.savedStoreuser,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREUSER_ACTION_TYPES.CREATE_STOREUSER.RESET:
    case STOREUSER_ACTION_TYPES.UPDATE_STOREUSER.RESET:
    case STOREUSER_ACTION_TYPES.DELETE_STOREUSER.RESET:
      return {
        ...state,
        savedStoreuser: {
          ...initialState.savedStoreuser
        }
      };
    /* ============================================ */
    case STOREUSER_ACTION_TYPES.FETCH_STOREUSER.IN_PROGRESS:
      return {
        ...state,
        storeuser: {
          ...state.storeuser,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STOREUSER_ACTION_TYPES.FETCH_STOREUSER.SUCCESS:
      return {
        ...state,
        storeuser: {
          ...state.storeuser,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STOREUSER_ACTION_TYPES.FETCH_STOREUSER.ERROR:
      return {
        ...state,
        storeuser: {
          ...state.storeuser,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STOREUSER_ACTION_TYPES.FETCH_STOREUSER.RESET:
      return {
        ...state,
        storeuser: {
          ...initialState.storeuser
        }
      }

    default:
      return state;
  }
}
export default storeuserReducer;