/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

// constant
const icons = { EmojiPeopleIcon };

// ==============================|| Store Owner List MENU ITEMS ||============================== //

const storeownerlist = {
    id: 'storeownerlist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Store Owner List',
            type: 'item',
            url: '/storeowner',
            icon: icons.EmojiPeopleIcon,
            breadcrumbs: false
        }
    ]
};

export default storeownerlist;
