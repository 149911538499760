import { createAction } from 'redux-actions';
import { STATES_ACTION_TYPES } from '../actionTypes/states.actionTypes';

export const searchStatesStart = createAction(STATES_ACTION_TYPES.SEARCH_STATES.START, (queryParams) => ({ queryParams }));
export const searchStatesInProgress = createAction(STATES_ACTION_TYPES.SEARCH_STATES.IN_PROGRESS);
export const searchStatesSuccess = createAction(STATES_ACTION_TYPES.SEARCH_STATES.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchStatesError = createAction(STATES_ACTION_TYPES.SEARCH_STATES.ERROR, (error) => ({ error }));
/* ============================================ */
export const createStatesStart = createAction(STATES_ACTION_TYPES.CREATE_STATES.START,
  (name) => ({ name }));
export const createStatesInProgress = createAction(STATES_ACTION_TYPES.CREATE_STATES.IN_PROGRESS);
export const createStatesSuccess = createAction(STATES_ACTION_TYPES.CREATE_STATES.SUCCESS, (data) => ({ data }));
export const createStatesError = createAction(STATES_ACTION_TYPES.CREATE_STATES.ERROR, (error) => ({ error }));
export const createStatesReset = createAction(STATES_ACTION_TYPES.CREATE_STATES.RESET);
/* ============================================ */
export const updateStatesStart = createAction(STATES_ACTION_TYPES.UPDATE_STATES.START,
  (stateId,name) => ({stateId,name }));
export const updateStatesInProgress = createAction(STATES_ACTION_TYPES.UPDATE_STATES.IN_PROGRESS);
export const updateStatesSuccess = createAction(STATES_ACTION_TYPES.UPDATE_STATES.SUCCESS, (data) => ({ data }));
export const updateStatesError = createAction(STATES_ACTION_TYPES.UPDATE_STATES.ERROR, (error) => ({ error }));
export const updateStatesReset = createAction(STATES_ACTION_TYPES.UPDATE_STATES.RESET);
/* ============================================ */
export const deleteStatesStart = createAction(STATES_ACTION_TYPES.DELETE_STATES.START, (stateId) => ({stateId }));
export const deleteStatesInProgress = createAction(STATES_ACTION_TYPES.DELETE_STATES.IN_PROGRESS);
export const deleteStatesSuccess = createAction(STATES_ACTION_TYPES.DELETE_STATES.SUCCESS, (data) => ({ data }));
export const deleteStatesError = createAction(STATES_ACTION_TYPES.DELETE_STATES.ERROR, (error) => ({ error }));

export const fetchStatesStart = createAction(STATES_ACTION_TYPES.FETCH_STATES.START, (id) => ({ id }));
export const fetchStatesInProgress = createAction(STATES_ACTION_TYPES.FETCH_STATES.IN_PROGRESS);
export const fetchStatesSuccess = createAction(STATES_ACTION_TYPES.FETCH_STATES.SUCCESS, (data) => ({ data }));
export const fetchStatesError = createAction(STATES_ACTION_TYPES.FETCH_STATES.ERROR, (error) => ({ error }));
export const fetchStatesReset = createAction(STATES_ACTION_TYPES.FETCH_STATES.RESET);
/* ============================================ */
export const fetchAllStatessStart = createAction(STATES_ACTION_TYPES.FETCH_ALL_STATESS.START);
export const fetchAllStatessInProgress = createAction(STATES_ACTION_TYPES.FETCH_ALL_STATESS.IN_PROGRESS);
export const fetchAllStatessSuccess = createAction(STATES_ACTION_TYPES.FETCH_ALL_STATESS.SUCCESS, (data) => ({ data }));
export const fetchAllStatessError = createAction(STATES_ACTION_TYPES.FETCH_ALL_STATESS.ERROR, (error) => ({ error }));
/* ============================================ */
export const searchProductToStateStart = createAction(STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.START, (productId) => ({ productId }));
export const searchProductToStateInProgress = createAction(STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.IN_PROGRESS);
export const searchProductToStateSuccess = createAction(STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.SUCCESS, (data) => ({ data }));
export const searchProductToStateError = createAction(STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.ERROR, (error) => ({ error }));