import { createActionTypes } from '../../utils/actionTypeCreator';

export const STOREOWNER_ACTION_TYPES = {  
  CREATE_STOREOWNER: createActionTypes("CREATE_STOREOWNER"),
  UPDATE_STOREOWNER:createActionTypes("UPDATE_STOREOWNER"),
  SEARCH_STOREOWNERS: createActionTypes('SEARCH_STOREOWNERS'),
  FETCH_STOREOWNER:createActionTypes('FETCH_STOREOWNER'),
  FETCH_ALL_STOREOWNERS:createActionTypes('FETCH_ALL_STOREOWNERS'),
  DELETE_STOREOWNER: createActionTypes('DELETE_STOREOWNER'),
  DELETE_STOREOWNERS: createActionTypes('DELETE_STOREOWNERS')
};