import { STATES_ACTION_TYPES } from '../actionTypes/states.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  statesList: createPaginatedEntity({ name: "name", direction: "asc" }),
  states: entityInitialState,
  statess: entityInitialState,
  savedStates: entityInitialState,
  productToStateList: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const statesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STATES_ACTION_TYPES.SEARCH_STATES.IN_PROGRESS:
      return {
        ...state,
        statesList: {
          ...state.statesList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATES_ACTION_TYPES.SEARCH_STATES.SUCCESS:
      return {
        ...state,
        statesList: {
          ...state.statesList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.statesList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STATES_ACTION_TYPES.SEARCH_STATES.ERROR:
      return {
        ...state,
        statesList: {
          ...state.statesList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATES_ACTION_TYPES.SEARCH_STATES.RESET:
      return {
        ...state,
        statesList: {
          ...initialState.statesList
        }
      };
    /* ============================================ */
    case STATES_ACTION_TYPES.CREATE_STATES.IN_PROGRESS:
    case STATES_ACTION_TYPES.UPDATE_STATES.IN_PROGRESS:
    case STATES_ACTION_TYPES.DELETE_STATES.IN_PROGRESS:
      return {
        ...state,
        savedStates: {
          ...state.savedStates,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATES_ACTION_TYPES.CREATE_STATES.SUCCESS:
    case STATES_ACTION_TYPES.UPDATE_STATES.SUCCESS:
    case STATES_ACTION_TYPES.DELETE_STATES.SUCCESS:
      return {
        ...state,
        savedStates: {
          ...state.savedStates,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STATES_ACTION_TYPES.CREATE_STATES.ERROR:
    case STATES_ACTION_TYPES.UPDATE_STATES.ERROR:
    case STATES_ACTION_TYPES.DELETE_STATES.ERROR:
      return {
        ...state,
        savedStates: {
          ...state.savedStates,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATES_ACTION_TYPES.CREATE_STATES.RESET:
    case STATES_ACTION_TYPES.UPDATE_STATES.RESET:
    case STATES_ACTION_TYPES.DELETE_STATES.RESET:
      return {
        ...state,
        savedStates: {
          ...initialState.savedStates
        }
      };
    /* ============================================ */
    case STATES_ACTION_TYPES.FETCH_STATES.IN_PROGRESS:
      return {
        ...state,
        states: {
          ...state.states,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATES_ACTION_TYPES.FETCH_STATES.SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STATES_ACTION_TYPES.FETCH_STATES.ERROR:
      return {
        ...state,
        states: {
          ...state.states,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATES_ACTION_TYPES.FETCH_STATES.RESET:
      return {
        ...state,
        states: {
          ...initialState.states
        }
      }
    /* ============================================ */
    case STATES_ACTION_TYPES.FETCH_ALL_STATESS.IN_PROGRESS:
      return {
        ...state,
        statess: {
          ...state.statess,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATES_ACTION_TYPES.FETCH_ALL_STATESS.SUCCESS:
      return {
        ...state,
        statess: {
          ...state.statess,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS
        }
      };
    case STATES_ACTION_TYPES.FETCH_ALL_STATESS.ERROR:
      return {
        ...state,
        statess: {
          ...state.statess,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATES_ACTION_TYPES.FETCH_ALL_STATESS.RESET:
      return {
        ...state,
        statess: {
          ...initialState.statess
        }
      };
    /* ============================================ */
    case STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.IN_PROGRESS:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.SUCCESS:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.productToStateList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.ERROR:
      return {
        ...state,
        productToStateList: {
          ...state.productToStateList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.RESET:
      return {
        ...state,
        productToStateList: {
          ...initialState.productToStateList
        }
      };
    /* ============================================ */
    default:
      return state;
  }
}
export default statesReducer;