import { createAction } from 'redux-actions';
import { STOREOWNER_ACTION_TYPES } from '../actionTypes/storeowner.actionTypes';

export const searchStoreownersStart = createAction(STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.START, (queryParams) => ({ queryParams }));
export const searchStoreownersInProgress = createAction(STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.IN_PROGRESS);
export const searchStoreownersSuccess = createAction(STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.SUCCESS, (data, queryParams) => ({ data, queryParams }));
export const searchStoreownersError = createAction(STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.ERROR, (error) => ({ error }));
/* ============================================ */
export const createStoreownerStart = createAction(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.START,
  (name, password,email,phone,address,no_of_stores) => ({ name, password,email,phone,address,no_of_stores }));
export const createStoreownerInProgress = createAction(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.IN_PROGRESS);
export const createStoreownerSuccess = createAction(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.SUCCESS, (data) => ({ data }));
export const createStoreownerError = createAction(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.ERROR, (error) => ({ error }));
export const createStoreownerReset = createAction(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.RESET);
/* ============================================ */
export const updateStoreownerStart = createAction(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.START,
  (storeownerId, name, password,email,phone,address,no_of_stores) => ({ storeownerId, name, password,email,phone,address,no_of_stores }));
export const updateStoreownerInProgress = createAction(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.IN_PROGRESS);
export const updateStoreownerSuccess = createAction(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.SUCCESS, (data) => ({ data }));
export const updateStoreownerError = createAction(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.ERROR, (error) => ({ error }));
export const updateStoreownerReset = createAction(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.RESET);

export const fetchStoreownerStart = createAction(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.START, (storeownerId) => ({ storeownerId}));
export const fetchStoreownerInProgress = createAction(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.IN_PROGRESS);
export const fetchStoreownerSuccess = createAction(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.SUCCESS, (data) => ({ data }));
export const fetchStoreownerError = createAction(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.ERROR, (error) => ({ error }));
export const fetchStoreownerReset = createAction(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.RESET);
/* ============================================ */
export const fetchAllStoreownersStart = createAction(STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.START);
export const fetchAllStoreownersInProgress = createAction(STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.IN_PROGRESS);
export const fetchAllStoreownersSuccess = createAction(STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.SUCCESS, (data) => ({ data }));
export const fetchAllStoreownersError = createAction(STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteStoreownerStart = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.START, (storeownerId,isOnlyItemOnPage) => ({storeownerId,isOnlyItemOnPage }));
export const deleteStoreownerInProgress = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.IN_PROGRESS);
export const deleteStoreownerSuccess = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.SUCCESS, (data) => ({ data }));
export const deleteStoreownerError = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.ERROR, (error) => ({ error }));
/* ============================================ */
export const deleteStoreownersStart = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.START, (store_owner_uuids) => ({store_owner_uuids}));
export const deleteStoreownersInProgress = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.IN_PROGRESS);
export const deleteStoreownersSuccess = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.SUCCESS, (data) => ({ data }));
export const deleteStoreownersError = createAction(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.ERROR, (error) => ({ error }));
