import { VOLUME_ACTION_TYPES } from '../actionTypes/volume.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  volume: entityInitialState,
  paymentMethod:entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const volumeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */
    case VOLUME_ACTION_TYPES.FETCH_VOLUME.IN_PROGRESS:
      return {
        ...state,
        volume: {
          ...state.volume,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_VOLUME.SUCCESS:
      return {
        ...state,
        volume: {
          ...state.volume,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_VOLUME.ERROR:
      return {
        ...state,
        volume: {
          ...state.volume,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_VOLUME.RESET:
      return {
        ...state,
        volume: {
          ...initialState.volume
        }
      }
        // /* ============================================ */
    case VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.IN_PROGRESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.SUCCESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.ERROR:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.RESET:
      return {
        ...state,
        paymentMethod: {
          ...initialState.paymentMethod
        }
      }
    /* ============================================ */
    default:
      return state;
  }
}
export default volumeReducer;