import { createSelector } from 'reselect';

const getResponsesState = (state) => state.states;

/* ============================================ */
export const getStatesList = createSelector(
  getResponsesState,
  (states) => states.statesList.data
);

export const getFetchStatesListQueryParams = createSelector(
  getResponsesState,
  (states) => states.statesList.queryParams
);

export const getFetchStatesListStatus = createSelector(
  getResponsesState,
  (states) => states.statesList.request_status
);
export const getSaveStatesStatus = createSelector(
  getResponsesState,
  (states) => states.savedStates.request_status
);
export const getStates = createSelector(
  getResponsesState,
  (states) => states.states.data
);
export const getAllStatess = createSelector(
  getResponsesState,
  (states) => states.statess.data
);
export const getProductToStateList = createSelector(
  getResponsesState,
  (states) => states.productToStateList.data
);
export const getEditStatesInitialValues = createSelector(
  getStates,
  (states) => {
    if (states) {
      return {
        name: states.name
      };
    }
    return {};
  }
);

export const statesInitialValues = {
  name: ''
};