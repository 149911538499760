import { createSelector } from 'reselect';

const getResponsesState = (state) => state.storeowner;

/* ============================================ */
export const getStoreownerList = createSelector(
  getResponsesState,
  (storeowner) => storeowner.storeownerList.data
);

export const getFetchStoreownerListQueryParams = createSelector(
  getResponsesState,
  (storeowner) => storeowner.storeownerList.queryParams
);

export const getFetchStoreownerListStatus = createSelector(
  getResponsesState,
  (storeowner) => storeowner.storeownerList.request_status
);
export const getSaveStoreownerStatus = createSelector(
  getResponsesState,
  (storeowner) => storeowner.savedStoreowner.request_status
);
export const getStoreowner = createSelector(
  getResponsesState,
  (storeowner) => storeowner.storeowner.data
);

export const getAllStoreowners = createSelector(
  getResponsesState,
  (storeowner) => storeowner.storeowners.data
);
export const getEditStoreownerInitialValues = createSelector(
  getStoreowner,
  (storeowner) => {
    if (storeowner) {
      return {
        name: storeowner.name,
        password: '',
        email: storeowner.email,
        phone: storeowner.phone,
        address: storeowner.address || '',
        no_of_stores: storeowner.no_of_stores,
        update_password: false
      };
    }
    return storeownerInitialValues;
  }
);

export const storeownerInitialValues = {
  name: '',
  password: '',
  email: '',
  phone: '',
  address: '',
  no_of_stores: '',
  update_password: false
};
