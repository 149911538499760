import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, _params, classes) => ({
  alert: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(1.5),
    wordBreak: 'break-word',

    '&>div:empty': {
      display: 'none'
    },
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline !important'
    }
  },
  error: {
    backgroundColor: theme.palette.error.light
  },
  info: {
    backgroundColor: theme.palette.info.light
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light
  },
  success: {
    backgroundColor: theme.palette.success.light
  },
  warning: {
    backgroundColor: theme.palette.warning.light
  }
})
);
