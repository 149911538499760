import client from "./client";

export const login = (email, password) =>
  client.post(`/user/login`, { email, password });

export const logout = () => client.post(`/user/logout`, {});

export const forgotPassword = (email) =>
  client.post(`/user/password/forgot`, { email });

export const resetPassword = (token, password) =>
  client.post(`/user/password/reset`, { token, password });

export const changePassword = (password) =>
  client.post(`/user/password/change`, { password });

export const update = (values) =>
  client.put(`/user/update`, values);

export const fetchAccount = () => client.get(`/account`);
  