import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './auth.reducer';
import brandReducer from './brand.reducer';
import productReducer from './product.reducer';
import warehouseReducer from './warehouse.reducer';
import storeownerReducer from './storeowner.reducer';
import storeReducer from './store.reducer';
import volumeReducer from './volume.reducer';
import statesReducer from './states.reducer';
import storeuserReducer from './storeuser.reducer';
import productToStateReducer from './productToState.reducer';
import statetoproductReducer from './statetoproduct.reducer';
import commonReducer from './common.reducer'
import stockListReducer from './stocklist.reducer';
import stockentryReducer from './stockentry.reducer';
import salesentryReducer from './salesentry.reducer';
// ==============================|| COMBINE REDUCER ||============================== //

const appReducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  brand:brandReducer,
  product:productReducer,
  warehouse:warehouseReducer,
  storeowner:storeownerReducer,
  store:storeReducer,
  volume:volumeReducer,
  states:statesReducer,
  storeuser:storeuserReducer,
  productToState:productToStateReducer,
  statetoproduct: statetoproductReducer,
  common: commonReducer,
  stocklist:stockListReducer,
  stockentry:stockentryReducer,
  salesentry:salesentryReducer
});

export default appReducer;