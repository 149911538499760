import { SALES_ENTRY_ACTION_TYPES } from '../actionTypes/salesentry.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  savedSalesEntry: entityInitialState,
  salesEntryList: createPaginatedEntity({ name: "name", direction: "asc" }),
  productsForSalesEntry: entityInitialState,
  productsForPaymentEntry: entityInitialState,
  savedPaymentEntry: entityInitialState
};

/* eslint-disable default-case, no-param-reassign */
const salesentryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    case SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.IN_PROGRESS:
      return {
        ...state,
        salesEntryList: {
          ...state.salesEntryList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.SUCCESS:
      return {
        ...state,
        salesEntryList: {
          ...state.salesEntryList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.salesEntryList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.ERROR:
      return {
        ...state,
        salesEntryList: {
          ...state.salesEntryList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_SALES_ENTRYS.RESET:
      return {
        ...state,
        salesEntryList: {
          ...initialState.salesEntryList
        }
      };

  /* ============================================ */
      case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.IN_PROGRESS:
      return {
        ...state,
        productsForSalesEntry: {
          ...state.productsForSalesEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.SUCCESS:
      return {
        ...state,
        productsForSalesEntry: {
          ...state.productsForSalesEntry,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.ERROR:
      return {
        ...state,
        productsForSalesEntry: {
          ...state.productsForSalesEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_SALES_ENTRY.RESET:
      return {
        ...state,
        productsForSalesEntry: {
          ...initialState.productsForSalesEntry
        }
      };

    /* ============================================ */
    case SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.IN_PROGRESS:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.IN_PROGRESS:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.IN_PROGRESS:
      return {
        ...state,
        savedSalesEntry: {
          ...state.savedSalesEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.SUCCESS:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.SUCCESS:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.SUCCESS:
      return {
        ...state,
        savedSalesEntry: {
          ...state.savedSalesEntry,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.ERROR:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.ERROR:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.ERROR:
      return {
        ...state,
        savedSalesEntry: {
          ...state.savedSalesEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_SALES_ENTRY.RESET:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.RESET:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.RESET:
      return {
        ...state,
        savedSalesEntry: {
          ...initialState.savedSalesEntry
        }
      };
    /* ============================================ */



     /* ============================================ */
     case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.IN_PROGRESS:
      return {
        ...state,
        productsForPaymentEntry: {
          ...state.productsForSalesEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.SUCCESS:
      return {
        ...state,
        productsForPaymentEntry: {
          ...state.productsForSalesEntry,
          data: action.payload.data,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.ERROR:
      return {
        ...state,
        productsForPaymentEntry: {
          ...state.productsForPaymentEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case SALES_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_PAYMENT_ENTRY.RESET:
      return {
        ...state,
        productsForPaymentEntry: {
          ...initialState.productsForPaymentEntry
        }
      };

    /* ============================================ */
    case SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.IN_PROGRESS:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.IN_PROGRESS:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.IN_PROGRESS:
      return {
        ...state,
        savedPaymentEntry: {
          ...state.savedPaymentEntry,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.SUCCESS:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.SUCCESS:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.SUCCESS:
      return {
        ...state,
        savedPaymentEntry: {
          ...state.savedPaymentEntry,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.ERROR:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.ERROR:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.ERROR:
      return {
        ...state,
        savedPaymentEntry: {
          ...state.savedPaymentEntry,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case SALES_ENTRY_ACTION_TYPES.CREATE_PAYMENT_ENTRY.RESET:
    // case SALES_ENTRY_ACTION_TYPES.UPDATE_SALES_ENTRY.RESET:
    // case SALES_ENTRY_ACTION_TYPES.DELETE_SALES_ENTRY.RESET:
      return {
        ...state,
        savedPaymentEntry: {
          ...initialState.savedPaymentEntry
        }
      };
    default:
      return state;
  }
}
export default salesentryReducer;