import * as actions from '../../src/store/actions/stockentry.actions';
import { STOCK_ENTRY_ACTION_TYPES } from '../../src/store/actionTypes/stockentry.actionTypes';
import * as api from '../api/stockentry.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchStockEntryListQueryParams
}
  from '../store/selectors/stockentry.selectors';

/* ============================================ */
export function* searchStockEntrys({ payload: { queryParams } }) {
  try {
    yield put(actions.searchStockEntrysInProgress());
    const paramsFromState = yield select(getFetchStockEntryListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStockEntrys,
      params

    );
    yield put(actions.searchStockEntrysSuccess(data, params));
  } catch (error) {
    yield put(actions.searchStockEntrysError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStockEntrys() {
  yield takeLatest(STOCK_ENTRY_ACTION_TYPES.SEARCH_STOCK_ENTRYS.START, searchStockEntrys);
}

/* ============================================ */
export function* searchProductsForStockEntry({ payload: { store_id, warehouse_id,entry_date, product_type, master_product_id, brand_id } }) {
  try {
    yield put(actions.searchProductForStockEntryInProgress());
    const data = yield call(
      api.searchProductsForStockEntry,
      store_id, warehouse_id,entry_date, product_type, master_product_id, brand_id
    );

    yield put(actions.searchProductForStockEntrySuccess(data));
  } catch (error) {
    yield put(actions.searchProductForStockEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProductsForStockEntry() {
  yield takeLatest(STOCK_ENTRY_ACTION_TYPES.SEARCH_PRODUCT_FOR_STOCK_ENTRY.START, searchProductsForStockEntry);
}

/* ============================================ */
export function* createStockEntry({ payload: { store_id, warehouse_id,entry_date,stock_entries } }) {
  try {
    yield put(actions.createStockEntryInProgress());

    const data = yield call(
      api.createStockEntry,
      store_id,warehouse_id, entry_date,stock_entries
    );
    yield call(notifier.notify, 'success', "Stock Entry added to database");
    yield put(actions.createStockEntrySuccess(data));

  } catch (error) {
    yield put(actions.createStockEntryError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStockEntry() {
  yield takeLatest(STOCK_ENTRY_ACTION_TYPES.CREATE_STOCK_ENTRY.START, createStockEntry);
}
