import { createSelector } from 'reselect';

const getResponsesState = (state) => state.stocklist;

/* ============================================ */
export const getStockList = createSelector(
  getResponsesState,
  (stocklist) => stocklist.stockList.data
);

export const getFetchStockListQueryParams = createSelector(
  getResponsesState,
  (stocklist) => stocklist.stockList.queryParams
);

export const getFetchStockListStatus = createSelector(
  getResponsesState,
  (stocklist) => stocklist.stockList.request_status
);

