import { createActionTypes } from '../../utils/actionTypeCreator';

export const USER_ACTION_TYPES = {  
  LOGIN: createActionTypes("LOGIN"),
  LOGOUT: createActionTypes("LOGOUT"),
  UPDATE: createActionTypes("UPDATE"),
  FORGOT_PASSWORD: createActionTypes("FORGOT_PASSWORD"),
  RESET_PASSWORD: createActionTypes("RESET_PASSWORD"),
  CHANGE_PASSWORD: createActionTypes("CHANGE_PASSWORD"),
  UPDATE_LOGIN_RESPONSE: "UPDATE_LOGIN_RESPONSE",
  FETCH_ACCOUNT: createActionTypes("FETCH_ACCOUNT"),
};
