import { createSelector } from 'reselect';

const getResponsesState = (state) => state.volume;

/* ============================================ */

export const getVolume = createSelector(
  getResponsesState,
  (volume) => volume.volume.data
);
export const getPaymentMethod = createSelector(
  getResponsesState,
  (volume) => volume.paymentMethod.data
);