import client from "./client";

export const searchBrands = (queryParams) => {
  return client.post(`/brand/search`, queryParams);
}

export const createBrand = (name, distillery, is_domestic) => {
  return client.post(`/brand`, { name, distillery, is_domestic });
}
export const updateBrand = (brandId, name, distillery, is_domestic) => {
  return client.put(`/brand/${brandId}`, { name, distillery, is_domestic });

}
export const deleteBrand = (brandId) => {
  return client.delete(`/brand/${brandId}/delete`);

}
export const getBrand = (brandId) => {
  return client.get(`/brand/${brandId}`);
}
export const deleteBrands = (brand_uuids) => {
  return client.post(`/brand/delete`, {brand_uuids});
}
