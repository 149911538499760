import { createSelector } from 'reselect';

const getResponsesState = (state) => state.storeuser;

/* ============================================ */
export const getStoreuserList = createSelector(
  getResponsesState,
  (storeuser) => storeuser.storeuserList.data
);

export const getFetchStoreuserListQueryParams = createSelector(
  getResponsesState,
  (storeuser) => storeuser.storeuserList.queryParams
);

export const getFetchStoreuserListStatus = createSelector(
  getResponsesState,
  (storeuser) => storeuser.storeuserList.request_status
);
export const getSaveStoreuserStatus = createSelector(
  getResponsesState,
  (storeuser) => storeuser.savedStoreuser.request_status
);
export const getStoreuser = createSelector(
  getResponsesState,
  (storeuser) => storeuser.storeuser.data
);

export const getEditStoreuserInitialValues = createSelector(
  getStoreuser,
  (storeuser) => {
    if (storeuser) {
      const stores = [];
      if (storeuser.stores) {
        storeuser.stores.forEach(store => {
          stores.push(store.store_uuid);
        });
      }
      
      return {
        name: storeuser.name,
        password:storeuser.password || '',
         email: storeuser.email,
         phone:storeuser.phone,
        address:storeuser.address || '',
         profile_type:storeuser.profile_type,
         stores:stores,
         store_owner_id:storeuser.employer_uuid,
         update_password: false
      };
    }
    return storeuserInitialValues;
  }
);

export const storeuserInitialValues = {
  name: '',
  password:"",
  email: '',
  phone:'',
  address:'',
  profile_type:'store_user',
  stores:[],
  update_password: false
};