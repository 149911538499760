import { createActionTypes } from '../../utils/actionTypeCreator';

export const  PRODUCT_ACTION_TYPES = {  
  CREATE_PRODUCT: createActionTypes("CREATE_ PRODUCT"),
  UPDATE_PRODUCT:createActionTypes("UPDATE_ PRODUCT"),
  SEARCH_PRODUCTS: createActionTypes('SEARCH_ PRODUCTS'),
  FETCH_PRODUCT:createActionTypes('FETCH_ PRODUCT'),
  FETCH_PRODUCTINFO:createActionTypes('FETCH_ PRODUCTINFO'),
  FETCH_ALL_PRODUCTS: createActionTypes('FETCH_ALL_PRODUCTS'),
  FETCH_ALL_MASTERPRODUCTS: createActionTypes('FETCH_ALL_MASTERPRODUCTS'),
  FETCH_INITIAL_QUANTITY: createActionTypes('FETCH_INITIAL_QUANTITY'),
  DELETE_PRODUCT: createActionTypes('DELETE_PRODUCT'),
  DELETE_PRODUCTS: createActionTypes('DELETE_PRODUCTS')
};