import * as actions from '../../src/store/actions/volume.actions';
import { VOLUME_ACTION_TYPES } from '../../src/store/actionTypes/volume.actionTypes';
import * as api from '../api/volume.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchVolumeListQueryParams
}
  from '../store/selectors/volume.selectors';

/* ============================================ */
export function* fetchVolume({ payload: { } }) {
  try {
    yield put(actions.fetchVolumeInProgress());
    const data = yield call(
      api.getConfig,

    );

    yield put(actions.fetchVolumeSuccess(data));
  } catch (error) {
    yield put(actions.fetchVolumeError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchVolume() {
  yield takeLatest(VOLUME_ACTION_TYPES.FETCH_VOLUME.START, fetchVolume);
}
export function* fetchPaymentMethod({ payload: { } }) {
  try {
    yield put(actions.fetchPaymentMethodInProgress());
    const data = yield call(
      api.getPaymentMethod,

    );

    yield put(actions.fetchPaymentMethodSuccess(data));
  } catch (error) {
    yield put(actions.fetchPaymentMethodError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchPaymentMethod() {
  yield takeLatest(VOLUME_ACTION_TYPES.FETCH_PAYMENTMETHOD.START, fetchPaymentMethod);
}