import * as actions from '../../src/store/actions/productToState.actions';
import { PRODUCT_TO_STATE_ACTION_TYPES } from '../../src/store/actionTypes/productToState.actionTypes';
import * as api from '../api/productToState.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchProductToStateListQueryParams
}
  from '../store/selectors/productToState.selectors';

/* ============================================ */
export function* searchProductToStates({ payload: {  masterProductId,stateId } }) {
  try {
    yield put(actions.searchProductToStatesInProgress());
   
    
    const data = yield call(
      api.searchProductToStates,
      masterProductId,stateId
      

    );
    yield put(actions.searchProductToStatesSuccess(data));
  } catch (error) {
    yield put(actions.searchProductToStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProductToStates() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.SEARCH_PRODUCT_TO_STATES.START, searchProductToStates);
}
/* ============================================ */
export function* createProductToState({ payload: { stateId,masterProductId,volumes } }) {
  try {
    yield put(actions.createProductToStateInProgress());

    const data = yield call(
      api.createProductToState,
      stateId,
      masterProductId,
      volumes
    );
    yield call(notifier.notify, 'success', "Product To State added to database");
    yield put(actions.createProductToStateSuccess(data));

  } catch (error) {
    yield put(actions.createProductToStateError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateProductToState() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.CREATE_PRODUCT_TO_STATE.START, createProductToState);
}
/* ============================================ */
export function* updateProductToState({ payload: { productToStateId, name, distillery, is_domestic } }) {

  try {
    yield put(actions.updateProductToStateInProgress());

    const data = yield call(
      api.updateProductToState,
      productToStateId,
      name,
      distillery,
      is_domestic
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateProductToStateSuccess(data));
    yield put(actions.searchProductToStatesStart());

  } catch (error) {
    yield put(actions.updateProductToStateError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateProductToState() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.UPDATE_PRODUCT_TO_STATE.START, updateProductToState);
}
/* ============================================ */
export function* deleteProductToState({ payload: { stateId,productId} }) {
  try {
    yield put(actions.deleteProductToStateInProgress());
    const data = yield call(
      api.deleteProductToState,
      stateId,productId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteProductToStateSuccess(data));
    yield put(actions.searchProductToStatesStart( ));

  } catch (error) {
    yield put(actions.deleteProductToStateError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchDeleteProductToState() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.DELETE_PRODUCT_TO_STATE.START, deleteProductToState);
}
/* ============================================ */
export function* fetchProductToState({ payload: { id } }) {
  try {
    yield put(actions.fetchProductToStateInProgress());
    const data = yield call(
      api.getProductToState,
      id
    );
    yield put(actions.fetchProductToStateSuccess(data));
  } catch (error) {
    yield put(actions.fetchProductToStateError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchProductToState() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_PRODUCT_TO_STATE.START, fetchProductToState);
}
export function* fetchAllProductToStates() {
  try {
    yield put(actions.fetchAllProductToStatesInProgress());
    const paramsFromState = yield select(getFetchProductToStateListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchProductToStates,
      queryParams
    );
    yield put(actions.fetchAllProductToStatesSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllProductToStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllProductToStates() {
  yield takeLatest(PRODUCT_TO_STATE_ACTION_TYPES.FETCH_ALL_PRODUCT_TO_STATES.START, fetchAllProductToStates);
}