import * as actions from '../../src/store/actions/states.actions';
import { STATES_ACTION_TYPES } from '../../src/store/actionTypes/states.actionTypes';
import * as api from '../api/states.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchStatesListQueryParams
}
  from '../store/selectors/states.selectors';

/* ============================================ */
export function* searchStates({ payload: { queryParams } }) {
 
  try {
    yield put(actions.searchStatesInProgress());
    const paramsFromState = yield select(getFetchStatesListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStatess,
      params

    );
    yield put(actions.searchStatesSuccess(data, params));
  } catch (error) {
    yield put(actions.searchStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStates() {
  yield takeLatest(STATES_ACTION_TYPES.SEARCH_STATES.START, searchStates);
}
/* ============================================ */
export function* createStates({ payload: { name } }) {
  try {
    yield put(actions.createStatesInProgress());

    const data = yield call(
      api.createStates,
      name
    );
    yield call(notifier.notify, 'success', "States added to database");
    yield put(actions.createStatesSuccess(data));
    yield put(actions.searchStatesStart());

  } catch (error) {
    yield put(actions.createStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStates() {
  yield takeLatest(STATES_ACTION_TYPES.CREATE_STATES.START, createStates);
}
/* ============================================ */
export function* updateStates({ payload: { stateId, name } }) {

  try {
    yield put(actions.updateStatesInProgress());

    const data = yield call(
      api.updateStates,
      stateId,
      name
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateStatesSuccess(data));
    yield put(actions.searchStatesStart());

  } catch (error) {
    yield put(actions.updateStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateStates() {
  yield takeLatest(STATES_ACTION_TYPES.UPDATE_STATES.START, updateStates);
}
/* ============================================ */
export function* fetchStates({ payload: { id } }) {
  
  try {
    yield put(actions.fetchStatesInProgress());
    const data = yield call(
      api.getStates,
      id
    );
    yield put(actions.fetchStatesSuccess(data));
  } catch (error) {
    yield put(actions.fetchStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchStates() {
  yield takeLatest(STATES_ACTION_TYPES.FETCH_STATES.START, fetchStates);
}
/* ============================================ */
export function* deleteStates({ payload: { stateId} }) {
  try {
    yield put(actions.deleteStatesInProgress());
    const data = yield call(
      api.deleteStates,
      stateId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStatesSuccess(data));
    yield put(actions.searchStatesStart( ));

  } catch (error) {
    yield put(actions.deleteStatesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteStates() {
  yield takeLatest(STATES_ACTION_TYPES.DELETE_STATES.START, deleteStates);
}

export function* fetchAllStatess() {
 
  try {
    yield put(actions.fetchAllStatessInProgress());
    const paramsFromState = yield select(getFetchStatesListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchStatess,
      queryParams
    );
    yield put(actions.fetchAllStatessSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllStatessError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllStatess() {
  yield takeLatest(STATES_ACTION_TYPES.FETCH_ALL_STATESS.START, fetchAllStatess);
}

/* ============================================ */
export function* searchProductToState({ payload: {  } }) {
 
  try {
    yield put(actions.searchProductToStateInProgress());
    const paramsFromState = yield select(getFetchStatesListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchProductToState ,
      params

    );
    yield put(actions.searchProductToStateSuccess(data));
  } catch (error) {
    yield put(actions.searchProductToStateError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchProductToState() {
  yield takeLatest(STATES_ACTION_TYPES.SEARCH_PRODUCTTOSTATE.START, searchProductToState);
}