import * as actions from '../../src/store/actions/storeowner.actions';
import { STOREOWNER_ACTION_TYPES } from '../../src/store/actionTypes/storeowner.actionTypes';
import * as storeActions from "../store/actions/common.actions";
import * as api from '../api/storeowner.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getAllStoreowners,
  getFetchStoreownerListQueryParams, getStoreownerList
}
  from '../store/selectors/storeowner.selectors';
import { getCurrentOwner } from 'store/selectors/common.selectors';

/* ============================================ */
export function* searchStoreowners({ payload: { queryParams } }) {
  try {
    yield put(actions.searchStoreownersInProgress());
    const paramsFromState = yield select(getFetchStoreownerListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchStoreowners,
      params

    );
    yield put(actions.searchStoreownersSuccess(data, params));
  } catch (error) {
    yield put(actions.searchStoreownersError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchStoreowners() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.SEARCH_STOREOWNERS.START, searchStoreowners);
}
/* ============================================ */
export function* createStoreowner({ payload: { name, password, email, phone, address, no_of_stores } }) {
  try {
    yield put(actions.createStoreownerInProgress());

    const data = yield call(
      api.createStoreowner,
      name,
      password, email, phone, address, no_of_stores
    );
    yield call(notifier.notify, 'success', "Storeowner added to database");
    yield put(actions.createStoreownerSuccess(data));
    yield put(storeActions.setSelectedStore(data.uuid, null));
    yield put(actions.searchStoreownersStart());

  } catch (error) {
    yield put(actions.createStoreownerError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateStoreowner() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.CREATE_STOREOWNER.START, createStoreowner);
}
/* ============================================ */
export function* updateStoreowner({ payload: { storeownerId, name, password, email, phone, address, no_of_stores } }) {

  try {
    yield put(actions.updateStoreownerInProgress());

    const data = yield call(
      api.updateStoreowner,
      storeownerId,
      name,
      password, email, phone, address, no_of_stores
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateStoreownerSuccess(data));
    yield put(actions.searchStoreownersStart());

  } catch (error) {
    yield put(actions.updateStoreownerError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateStoreowner() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.UPDATE_STOREOWNER.START, updateStoreowner);
}
/* ============================================ */
export function* fetchStoreowner({ payload: { storeownerId } }) {
  try {
    yield put(actions.fetchStoreownerInProgress());
    const data = yield call(
      api.getStoreowner,
      storeownerId
    );
    yield put(actions.fetchStoreownerSuccess(data));

  } catch (error) {
    yield put(actions.fetchStoreownerError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchStoreowner() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.FETCH_STOREOWNER.START, fetchStoreowner);
}
export function* fetchAllStoreowners() {
  try {
    yield put(actions.fetchAllStoreownersInProgress());
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchStoreowners,
      queryParams
    );
    yield put(actions.fetchAllStoreownersSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllStoreownersError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllStoreowners() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.FETCH_ALL_STOREOWNERS.START, fetchAllStoreowners);
}
/* ============================================ */
export function* deleteStoreowner({ payload: { storeownerId,isOnlyItemOnPage } }) {
  try {
    yield put(actions.deleteStoreownerInProgress());
    const data = yield call(
      api.deleteStoreowner,
      storeownerId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStoreownerSuccess(data));
    const paramsFromState = yield select(getFetchStoreownerListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchStoreownersStart({...paramsFromState, page: (paramsFromState.page-1)}));
    }else{
      yield put(actions.searchStoreownersStart( ));
    }
    yield call(fetchAllStoreowners);

    const currentOwner = yield select(getCurrentOwner); 

    // if deleted store is same as the current selected owner, then change selected store
    if(currentOwner  == storeownerId)
    {
      const currentList = (yield select(getAllStoreowners)).items;
      if(currentList.length == 0)
      {
        yield put(storeActions.setSelectedStoreowner(null));
      }
      else
      {
        yield put(storeActions.setSelectedStoreowner(currentList[0].uuid));
      }
    }


  } catch (error) {
    yield put(actions.deleteStoreownerError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteStoreowner() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNER.START, deleteStoreowner);
}
/* ============================================ */
export function* deleteStoreowners({ payload: { store_owner_uuids} }) {
  try {
    yield put(actions.deleteStoreownersInProgress());
    const data = yield call(
      api.deleteStoreowners,
      store_owner_uuids
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteStoreownersSuccess(data));
    yield put(actions.searchStoreownersStart( ));


  } catch (error) {
    yield put(actions.deleteStoreownersError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteStoreowners() {
  yield takeLatest(STOREOWNER_ACTION_TYPES.DELETE_STOREOWNERS.START, deleteStoreowners);
}