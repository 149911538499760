import { WAREHOUSE_ACTION_TYPES } from '../actionTypes/warehouse.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  warehouseList: createPaginatedEntity({ name: "name", direction: "asc" }),
  warehouse: entityInitialState,
  savedWarehouse: entityInitialState,
  warehouses: entityInitialState,
};

/* eslint-disable default-case, no-param-reassign */
const warehouseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.IN_PROGRESS:
      return {
        ...state,
        warehouseList: {
          ...state.warehouseList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.SUCCESS:
      return {
        ...state,
        warehouseList: {
          ...state.warehouseList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.warehouseList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.ERROR:
      return {
        ...state,
        warehouseList: {
          ...state.warehouseList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.RESET:
      return {
        ...state,
        warehouseList: {
          ...initialState.warehouseList
        }
      };
    /* ============================================ */
    case WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.IN_PROGRESS:
    case WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.IN_PROGRESS:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.IN_PROGRESS:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.IN_PROGRESS:
      return {
        ...state,
        savedWarehouse: {
          ...state.savedWarehouse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.SUCCESS:
    case WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.SUCCESS:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.SUCCESS:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.SUCCESS:
      return {
        ...state,
        savedWarehouse: {
          ...state.savedWarehouse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.ERROR:
    case WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.ERROR:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.ERROR:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.ERROR:
      return {
        ...state,
        savedWarehouse: {
          ...state.savedWarehouse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.RESET:
    case WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.RESET:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.RESET:
    case WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.RESET:
      return {
        ...state,
        savedWarehouse: {
          ...initialState.savedWarehouse
        }
      };
    /* ============================================ */
    case WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.IN_PROGRESS:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.SUCCESS:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.ERROR:
      return {
        ...state,
        warehouse: {
          ...state.warehouse,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.RESET:
      return {
        ...state,
        warehouse: {
          ...initialState.warehouse
        }
      }
 // /* ============================================ */
 case WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.IN_PROGRESS:
  return {
    ...state,
    warehouses: {
      ...state.warehouses,
      request_status: ACTION_STATUS.IN_PROGRESS,
    }
  };
case WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.SUCCESS:
  return {
    ...state,
    warehouses: {
      ...state.warehouses,
      data: action.payload.data,
      request_status: ACTION_STATUS.SUCCESS
    }
  };
case WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.ERROR:
  return {
    ...state,
    warehouses: {
      ...state.warehouses,
      request_status: ACTION_STATUS.ERROR,
      error: action.payload
    }
  };
case WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.RESET:
  return {
    ...state,
    warehouses: {
      ...initialState.warehouses
    }
  };
    default:
      return state;
  }
}
export default warehouseReducer;