import * as actions from '../../src/store/actions/warehouse.actions';
import { WAREHOUSE_ACTION_TYPES } from '../../src/store/actionTypes/warehouse.actionTypes';
import * as api from '../api/warehouse.api';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { notifier } from '../utils/notifier'
import {
  getFetchWarehouseListQueryParams
}
  from '../store/selectors/warehouse.selectors';

/* ============================================ */
export function* searchWarehouses({ payload: { queryParams } }) {
  try {
    yield put(actions.searchWarehousesInProgress());
    const paramsFromState = yield select(getFetchWarehouseListQueryParams);
    const params = queryParams || paramsFromState;
    const data = yield call(
      api.searchWarehouses,
      params

    );
    yield put(actions.searchWarehousesSuccess(data, params));
  } catch (error) {
    yield put(actions.searchWarehousesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchSearchWarehouses() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.SEARCH_WAREHOUSES.START, searchWarehouses);
}
/* ============================================ */
export function* createWarehouse({ payload: { name, street_line_1, street_line_2,city,state_id,zip,contacts } }) {
  try {
    yield put(actions.createWarehouseInProgress());

    const data = yield call(
      api.createWarehouse,
      name, street_line_1, street_line_2,city,state_id,zip,contacts
    );
    yield call(notifier.notify, 'success', "Warehouse added to database");
    yield put(actions.createWarehouseSuccess(data));
    yield put(actions.searchWarehousesStart());

  } catch (error) {
    yield put(actions.createWarehouseError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchCreateWarehouse() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.CREATE_WAREHOUSE.START, createWarehouse);
}
/* ============================================ */
export function* updateWarehouse({ payload: { warehouseId, name, street_line_1, street_line_2,city,state_id,zip,contacts } }) {

  try {
    yield put(actions.updateWarehouseInProgress());

    const data = yield call(
      api.updateWarehouse,
      warehouseId,
      name, street_line_1, street_line_2,city,state_id,zip,contacts
    );
    yield call(notifier.notify, 'success', "Changes saved successfully");
    yield put(actions.updateWarehouseSuccess(data));
    yield put(actions.searchWarehousesStart());

  } catch (error) {
    yield put(actions.updateWarehouseError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchUpdateWarehouse() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.UPDATE_WAREHOUSE.START, updateWarehouse);
}
/* ============================================ */
export function* deleteWarehouse({ payload: { warehouseId, isOnlyItemOnPage} }) {
  try {
    yield put(actions.deleteWarehouseInProgress());
    const data = yield call(
      api.deleteWarehouse,
      warehouseId
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteWarehouseSuccess(data));
    const paramsFromState = yield select(getFetchWarehouseListQueryParams);
    if(isOnlyItemOnPage && paramsFromState.page != 0)
    {
      yield put(actions.searchWarehousesStart({...paramsFromState, page: (paramsFromState.page-1)}));
    }else{
      yield put(actions.searchWarehousesStart( ));
    }

  } catch (error) {
    yield put(actions.deleteWarehouseError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteWarehouse() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSE.START, deleteWarehouse);
}
/* ============================================ */
export function* fetchWarehouse({ payload: {warehouseId } }) {
  try {
    yield put(actions.fetchWarehouseInProgress());
    const data = yield call(
      api.getWarehouse,
      warehouseId
    );
    yield put(actions.fetchWarehouseSuccess(data));
  } catch (error) {
    yield put(actions.fetchWarehouseError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchWarehouse() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.FETCH_WAREHOUSE.START, fetchWarehouse);
}

export function* fetchAllWarehouses() {
  try {
    yield put(actions.fetchAllWarehousesInProgress());
    const paramsFromState = yield select(getFetchWarehouseListQueryParams);
    const queryParams = { sortOrder: { "name": "name", "direction": "asc" } };
    const data = yield call(
      api.searchWarehouses,
      queryParams
    );
    yield put(actions.fetchAllWarehousesSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllWarehousesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}

export function* watchFetchAllWarehouses() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.FETCH_ALL_WAREHOUSES.START, fetchAllWarehouses);
}
/* ============================================ */
export function* deleteWarehouses({ payload: { warehouse_uuids} }) {
  try {
    yield put(actions.deleteWarehousesInProgress());
    const data = yield call(
      api.deleteWarehouses,
      warehouse_uuids
    );
    yield call(notifier.notify, 'success', "deleted successfully");
    yield put(actions.deleteWarehousesSuccess(data));
    yield put(actions.searchWarehousesStart( ));


  } catch (error) {
    yield put(actions.deleteWarehousesError(error.text));
    yield call(notifier.alert, 'error', error.text);
  }
}
export function* watchDeleteWarehouses() {
  yield takeLatest(WAREHOUSE_ACTION_TYPES.DELETE_WAREHOUSES.START, deleteWarehouses);
}