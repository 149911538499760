/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| Create Brand MENU ITEMS ||============================== //

const createbrand = {
    id: 'createbrand',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Create Brand',
            type: 'item',
            url: '/createbrand/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default createbrand;
