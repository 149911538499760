import { createSelector } from 'reselect';

const getResponsesState = (state) => state.productToState;

/* ============================================ */
export const getProductToStateList = createSelector(
  getResponsesState,
  (productToState) => productToState.productToStateList.data
);

export const getFetchProductToStateListQueryParams = createSelector(
  getResponsesState,
  (productToState) => productToState.productToStateList.queryParams
);

export const getFetchProductToStateListStatus = createSelector(
  getResponsesState,
  (productToState) => productToState.productToStateList.request_status
);
export const getSaveProductToStateStatus = createSelector(
  getResponsesState,
  (productToState) => productToState.savedProductToState.request_status
);
export const getProductToState = createSelector(
  getResponsesState,
  (productToState) => productToState.productToState.data
);
export const getAllProductToStates = createSelector(
  getResponsesState,
  (productToState) => productToState.productToStates.data
);
export const productToStateInitialValues = {
  volumes:[{
    volume_id:'',
    price:''
  }],
};