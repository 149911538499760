/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import LiquorIcon from '@mui/icons-material/Liquor';

// constant
const icons = { LiquorIcon };

// ==============================|| Product List MENU ITEMS ||============================== //

const productlist = {
    id: 'productlist',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Product List',
            type: 'item',
            url: '/products',
            icon: icons.LiquorIcon,
            breadcrumbs: false
        }
    ]
};

export default productlist;
