/* eslint-disable */

import dashboard from './dashboard';
import brandlist from './brandlist';
import productlist from './productlist';
import storelist from './storelist';
import userlist from './userlist';
import warehouselist from './warehouselist';
import stocklist from './stocklist';
import mainreport from './mainreport';
import createbrand from './createbrand';
import statelist from './statelist';
import stockentry from './stockentry';
import salesentry from './salesentry';
import storeownerlist from './storeownerlist';
import managepaymentmethods from './managepaymentmethods';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, brandlist, productlist, statelist, createbrand, storeownerlist, storelist, userlist, warehouselist, stocklist, stockentry, salesentry, mainreport, managepaymentmethods]
};

export default menuItems;
