import { STORE_ACTION_TYPES } from '../actionTypes/store.actionTypes';
import { ACTION_STATUS, entityInitialState, createPaginatedEntity } from '../../utils/constants';

const initialState = {
  storeList: createPaginatedEntity({ name: "name", direction: "asc" }),
  store: entityInitialState,
  stores: entityInitialState,
  savedStore: entityInitialState,
  selectedStoreList: createPaginatedEntity({ name: "name", direction: "asc" }),
};

/* eslint-disable default-case, no-param-reassign */
const storeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    /* ============================================ */

    /* ============================================ */
    case STORE_ACTION_TYPES.SEARCH_STORES.IN_PROGRESS:
      return {
        ...state,
        storeList: {
          ...state.storeList,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STORE_ACTION_TYPES.SEARCH_STORES.SUCCESS:
      return {
        ...state,
        storeList: {
          ...state.storeList,
          data: action.payload.data,
          queryParams: action.payload.queryParams || state.storeList.queryParams,
          request_status: ACTION_STATUS.SUCCESS,
        }
      };
    case STORE_ACTION_TYPES.SEARCH_STORES.ERROR:
      return {
        ...state,
        storeList: {
          ...state.storeList,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STORE_ACTION_TYPES.SEARCH_STORES.RESET:
      return {
        ...state,
        storeList: {
          ...initialState.storeList
        }
      };
    /* ============================================ */
    case STORE_ACTION_TYPES.CREATE_STORE.IN_PROGRESS:
    case STORE_ACTION_TYPES.UPDATE_STORE.IN_PROGRESS:
    case STORE_ACTION_TYPES.DELETE_STORE.IN_PROGRESS:
      return {
        ...state,
        savedStore: {
          ...state.savedStore,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STORE_ACTION_TYPES.CREATE_STORE.SUCCESS:
    case STORE_ACTION_TYPES.UPDATE_STORE.SUCCESS:
    case STORE_ACTION_TYPES.DELETE_STORE.SUCCESS:
      return {
        ...state,
        savedStore: {
          ...state.savedStore,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STORE_ACTION_TYPES.CREATE_STORE.ERROR:
    case STORE_ACTION_TYPES.UPDATE_STORE.ERROR:
    case STORE_ACTION_TYPES.DELETE_STORE.ERROR:
      return {
        ...state,
        savedStore: {
          ...state.savedStore,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STORE_ACTION_TYPES.CREATE_STORE.RESET:
    case STORE_ACTION_TYPES.UPDATE_STORE.RESET:
    case STORE_ACTION_TYPES.DELETE_STORE.RESET:
      return {
        ...state,
        savedStore: {
          ...initialState.savedStore
        }
      };
    /* ============================================ */
    case STORE_ACTION_TYPES.FETCH_STORE.IN_PROGRESS:
      return {
        ...state,
        store: {
          ...state.store,
          request_status: ACTION_STATUS.IN_PROGRESS,
        }
      };
    case STORE_ACTION_TYPES.FETCH_STORE.SUCCESS:
      return {
        ...state,
        store: {
          ...state.store,
          request_status: ACTION_STATUS.SUCCESS,
          data: action.payload.data
        }
      };
    case STORE_ACTION_TYPES.FETCH_STORE.ERROR:
      return {
        ...state,
        store: {
          ...state.store,
          request_status: ACTION_STATUS.ERROR,
          error: action.payload
        }
      };
    case STORE_ACTION_TYPES.FETCH_STORE.RESET:
      return {
        ...state,
        store: {
          ...initialState.store
        }
      };
 // /* ============================================ */
 case STORE_ACTION_TYPES.FETCH_ALL_STORES.IN_PROGRESS:
  return {
    ...state,
   stores: {
      ...state.stores,
      request_status: ACTION_STATUS.IN_PROGRESS,
    }
  };
case STORE_ACTION_TYPES.FETCH_ALL_STORES.SUCCESS:
  return {
    ...state,
   stores: {
      ...state.stores,
      data: action.payload.data,
      request_status: ACTION_STATUS.SUCCESS
    }
  };
case STORE_ACTION_TYPES.FETCH_ALL_STORES.ERROR:
  return {
    ...state,
   stores: {
      ...state.stores,
      request_status: ACTION_STATUS.ERROR,
      error: action.payload
    }
  };
case STORE_ACTION_TYPES.FETCH_ALL_STORES.RESET:
  return {
    ...state,
   stores: {
      ...initialState.stores
    }
  };
 
    default:
      return state;
  }
}
export default storeReducer;