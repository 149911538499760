/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

// constant
const icons = { PointOfSaleIcon };

// ==============================|| Sales Entry MENU ITEMS ||============================== //

const salesentry = {
    id: 'salesentry',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Sales Entry',
            type: 'item',
            url: '/salesentry',
            icon: icons.PointOfSaleIcon,
            breadcrumbs: false
        }
    ]
};

export default salesentry;
