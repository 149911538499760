import { createSelector } from 'reselect';

const getResponsesState = (state) => state.salesentry;

export const getSalesEntryList = createSelector(
  getResponsesState,
  (salesentry) => salesentry.salesEntryList.data
);

export const getFetchSalesEntryListQueryParams = createSelector(
  getResponsesState,
  (salesentry) => salesentry.salesEntryList.queryParams
);

export const getFetchSalesEntryListStatus = createSelector(
  getResponsesState,
  (salesentry) => salesentry.salesEntryList.request_status
);
export const getProductForSalesEntry = createSelector(
  getResponsesState,
  (salesentry) => salesentry.productsForSalesEntry.data
);

export const getProductsForSalesEntryStatus = createSelector(
  getResponsesState,
  (salesentry) => salesentry.productsForSalesEntry.request_status
);
export const getSaveSalesEntryStatus = createSelector(
    getResponsesState,
    (salesentry) => salesentry.savedSalesEntry.request_status
  );
  export const getProductForPaymentEntry = createSelector(
    getResponsesState,
    (salesentry) => salesentry.productsForPaymentEntry.data
  );
  export const getProductsForPaymentEntryStatus = createSelector(
    getResponsesState,
    (salesentry) => salesentry.productsForPaymentEntry.request_status
  );
  export const getSavePaymentEntryStatus = createSelector(
      getResponsesState,
      (salesentry) => salesentry.savedPaymentEntry.request_status
    );
  export const salesEntryInitialValues = {
    sales_entries:[{
      product_id:'',
      quantity_sold:'',
      selling_price:''
    }],
  };