import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import NotificationToast from 'ui-component/extended/NotificationToast';
import { ConfirmProvider } from 'ui-component/extended/confirm';

const confirmDialogDefaultProps = {
  description: (
    <>
      Please type <b>DELETE</b> to confirm.
    </>
  ),
  confirmationText: "Delete",
  confirmationKeyword: "DELETE",
  confirmationButtonProps: {
    variant: "contained",
    color: "error"
  },
  confirmationButtonProps: {
    variant: "contained",
    color: "error"
  },
  confirmationKeywordTextFieldProps: {
    variant: "outlined",
    color: "primary",
    size: "small"
  },
  cancelButtonProps: {
    color: "default",
    variant: "contained",
  }
};


// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ConfirmProvider defaultOptions={confirmDialogDefaultProps}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />

          </NavigationScroll>
        </ConfirmProvider>
      </ThemeProvider>
      <NotificationToast />
    </StyledEngineProvider>
  );
};

export default App;
