// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
//import menuItem from 'menu-items';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dashboard from '../../../../menu-items/dashboard';
import brandlist from '../../../../menu-items/brandlist';
import productlist from '../../../../menu-items/productlist';
import storelist from '../../../../menu-items/storelist';
import userlist from '../../../../menu-items/userlist';
import warehouselist from '../../../../menu-items/warehouselist';
import stocklist from '../../../../menu-items/stocklist';
import mainreport from '../../../../menu-items/mainreport';
import stockentry from '../../../../menu-items/stockentry';
import salesentry from '../../../../menu-items/salesentry';
import storeownerlist from '../../../../menu-items/storeownerlist';
import statelist from '../../../../menu-items/statelist';
import managepaymentmethods from '../../../../menu-items/managepaymentmethods';
import { getCurrentUser } from 'store/selectors/auth.selectors';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCurrentOwner } from 'store/selectors/common.selectors';
// ==============================|| SIDEBAR DRAWER ||============================== //

const getItems = (user, currentOwner) => {
  let items = [];
  if(user)
  {
    if ( user.role == 'superuser') {
      items = [dashboard, brandlist, productlist, warehouselist, statelist, storeownerlist];
      if(currentOwner)
      {
        items = items.concat([storelist, userlist, stockentry, stocklist, salesentry, mainreport]);
      }
    }
    else if ( user.role == 'store_owner') {
      items = [dashboard, storelist, userlist, stockentry, stocklist, salesentry, mainreport];
    }
    else if ( user.role == 'store_manager') {
      items = [dashboard, stockentry, stocklist, salesentry, mainreport];
    }
    else if ( user.role == 'store_user') {
      items = [dashboard, stockentry, stocklist, salesentry];
    }
    else {
      items = [  stockentry, stocklist, salesentry];
    }
  }

  return items;

}
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const currentUser = useSelector(getCurrentUser);
  const currentOwner = useSelector(getCurrentOwner);

  const navItems = getItems(currentUser, currentOwner).map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  return <>{navItems}</>;
  
};

export default MenuList;
