/* eslint no-restricted-syntax: "off", no-param-reassign: "off" */

import axios from 'axios';
import Qs from 'query-string';

import { getCookie, removeCookie } from '../utils/utils';



const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'T-Offset': new Date().getTimezoneOffset() * -1
  },
  paramsSerializer: (params) =>
    Qs.stringify(params, { arrayFormat: 'bracket' })
});
/**
 * The default url param serializer. For all those GET methods with filters.
 * @param params
 * @returns {*|string}
 */

client.interceptors.request.use((config) => {
  const token = getCookie('X-AuthToken');

  if (token) {
    config.headers['X-AuthToken'] = token;
  }

  return config;
});

client.interceptors.response.use(
  (response) => { return response.data; },
  (error) => {

    if (error.response !== undefined) {
      const host =
        window.location.hostname !== 'localhost'
          ? APP_URL
          : `http://localhost:${window.location.port}`;

      if (error.response.status === 401 || error.response.status === 403) {
        removeCookie('X-AuthToken');
        localStorage.clear();
        window.location.replace(`${host}/login`);
      } else if (error.response.status === 412) {
        sessionStorage.setItem('isPreconditionFailed', 'true');
        window.location.replace(`${host}/update-password`);
      }

      const throwableError = {
        code: error.response.status,
        text: error.response.data.message || error.response.data.messages
      };

      if (typeof error.response.data === 'object' && !throwableError.text) {
        let text = "<ul>"
        Object.entries(error.response.data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach(elem => text = text + `<li>${elem}`);
          }
          else {
            text = text + `<li>${value}`;
          }

        });
        text = text + '</ul>';
        throwableError.text = text;
      }

      throw throwableError;
    } else {
      error.text = error.message;
      throw error;
    }
  }
);

export default client;
