import client from "./client";

export const searchStockEntrys = (queryParams) => {
  return client.post(`/stockSummary/search`, queryParams);
}

export const searchProductsForStockEntry = (store_id, warehouse_id, entry_date,
  product_type, master_product_id, brand_id) => {
  return client.get(`/stock_entry/${store_id}/products/${warehouse_id}/${entry_date}`, { params: { product_type, master_product_id, brand_id } });
}

export const createStockEntry = (store_id, warehouse_id, entry_date, stock_entries) => {
  return client.post(`/stock_entry/${store_id}/products/${warehouse_id}/${entry_date}`, { stock_entries });
}
