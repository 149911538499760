/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import PaymentsIcon from '@mui/icons-material/Payments';

// constant
const icons = { PaymentsIcon };

// ==============================|| Manage Payment Methods MENU ITEMS ||============================== //

const managepaymentmethods = {
    id: 'managepaymentmethods',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Payment Methods',
            type: 'item',
            url: '/paymentmethods',
            icon: icons.PaymentsIcon,
            breadcrumbs: false
        }
    ]
};

export default managepaymentmethods;
