import { createActionTypes } from '../../utils/actionTypeCreator';

export const STATES_ACTION_TYPES = {  
  CREATE_STATES: createActionTypes("CREATE_STATES"),
  UPDATE_STATES:createActionTypes("UPDATE_STATES"),
  SEARCH_STATES: createActionTypes('SEARCH_STATES'),
  FETCH_STATES:createActionTypes('FETCH_STATES'),
  FETCH_ALL_STATESS:createActionTypes('FETCH_ALL_STATESS'),
  SEARCH_PRODUCTTOSTATE: createActionTypes('SEARCH_PRODUCTTOSTATE'),
  DELETE_STATES: createActionTypes('DELETE_STATES')
};