/* eslint-disable */

// assets
import { IconDashboard } from '@tabler/icons';
import AssessmentIcon from '@mui/icons-material/Assessment';

// constant
const icons = { AssessmentIcon };

// ==============================|| Report MENU ITEMS ||============================== //

const mainreport = {
    id: 'mainreport',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Report',
            type: 'item',
            url: '/report',
            icon: icons.AssessmentIcon,
            breadcrumbs: false
        }
    ]
};

export default mainreport;